import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";

@Injectable()
export class SuscripcionesService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/suscripciones";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.clientes,
      this.appService.servicios.suscripciones
    ];
  }

  public getAcciones(item: any): AccionInterface[] {
    let acciones: AccionInterface[] = [{
      titulo: "Más info",
      accion: EnumAccion.SUSCRIPCION_DETALLE,
      icono: "plus",
      datos: { id: item.id }
    }];

    if (item.entidad != null) {
      acciones.push({
        titulo: "Ver Cliente",
        accion: EnumAccion.ENTIDAD_DETALLE,
        icono: "male",
        datos: { id: item.entidad.id }
      });
    }

    if (item.estado == 1) {
      acciones.push({
        titulo: "Cancelada",
        accion: EnumAccion.SUSCRIPCION_CANCELAR,
        icono: "ban",
        datos: { id: item.id }
      });
    }

    if (item.estado != 99) {
      acciones.push({
        titulo: "Eliminar",
        accion: EnumAccion.SUSCRIPCION_ELIMINAR,
        icono: "trash-alt",
        datos: { id: item.id, estado: 0 }
      })
    }

    return acciones;
  }
}
