import { Input } from "@angular/core";
import {
  IPaginado,
  IUpdateComponent,
  IServicio,
  AccionInterface,
  FiltroInterface
} from "@app/core/interfaces";
import { Observable } from "rxjs";
import { DatePipe } from "@angular/common";
import { dateToFechaDesdeISOString, dateToFechaHastaISOString, dateToFechaHasta } from "@app/utils";

export class ListadoBaseComponent<T> implements IUpdateComponent {

  public filtrosBar: FiltroInterface = {};

  public maxDate = dateToFechaHasta(new Date());

  @Input()
  public paginado: IPaginado = {
    cantidadTotal: 0,
    cantidadPagina: 10,
    paginaSeleccionada: 1
  };

  private _filtros: any = {};
  get filtros(): any {
    return this._filtros;
  }

  @Input()
  set filtros(filtros: any) {
    this._filtros = filtros;

    if (this.filtroFecha) {
      this._filtros.fechaDesde = dateToFechaDesdeISOString(this.filtroFecha);
      this._filtros.fechaHasta = dateToFechaHastaISOString(this.filtroFecha);
    };

    if (this.filtroActivo) {
      this._filtros.estado = 1;
    }

    if (this.filtroInactivo) {
      this._filtros.estado = 0;
    }

    if (this.filtroAsignado) {
      this._filtros.asignacion = true;
    }

    if (this.filtroNoAsignado) {
      this._filtros.asignacion = false;
    }

    if (this.filtroUsuario != null) {
      this._filtros.usuario = this.filtroUsuario;
    }

    if (this.filtroTexto != null && this.filtroTexto != "") {
      this._filtros.texto = this.filtroTexto;
    } else {
      delete this._filtros.texto;
    }

    if (this.filtroEsSinRutina) {
      this.filtros.esSinRutina = true;
    }

    this.cargando = true;
    this.cargarDatos().subscribe(_ => {
      this.cargando = false;
    });
  }

  @Input()
  esPaginado: boolean = true;

  @Input()
  maxSize: number = 5;

  public filtroActivo: boolean = false;
  public filtroInactivo: boolean = false;
  public filtroTexto: string = "";
  public filtroFecha: Date;
  public filtroEsSinRutina: boolean = false;
  public filtroAsignado: boolean = false;
  public filtroNoAsignado: boolean = false;
  public filtroUsuario: number;z

  public cargando: boolean;
  public updating: boolean = false;
  public error: boolean;
  public mensaje: string;
  public items: T[] = [];
  public itemsExtra: any;

  public accionCrear: AccionInterface;
  public datePipe: DatePipe = new DatePipe("en");

  constructor(public servicio: IServicio<T>) { }

  cargarDatos(): Observable<any> {
    this.updating = true;
    return new Observable(observer => {
      let filtros: any = {};
      if (this.esPaginado) {
        filtros.pagina = this.paginado.paginaSeleccionada;
        filtros.cantidad = this.paginado.cantidadPagina;
      }
      this.servicio.get({ ...this.filtros, ...filtros, ...this.filtrosBar }).subscribe(
        res => {
          this.items = res.listado;
          this.itemsExtra = res.extra;
          this.paginado.cantidadTotal = res.cantidad;
          this.error = false;
          this.mensaje = `Visualizando ${res.listado.length} de ${
            res.cantidad
            }`;
          this.updating = false;
          observer.next();
          observer.complete();
        },
        error => {
          this.items = [];
          this.itemsExtra = {};
          this.error = true;
          this.mensaje = error.error;
          observer.error();
        }
      );
    });
  }

  descargar() {
    this.servicio.descargar({ ...this.filtros }).subscribe(
      res => {
        console.log("descargar");
      },
      error => {
      }
    );
  }

  update() {
    this.onUpdate().subscribe(_ => { });
  }

  onUpdate(): Observable<any> {
    return this.cargarDatos();
  }

  onfiltrerBarChange(filtros){
    this.filtrosBar = filtros;
    this.onUpdate().subscribe(_ => { });
  }

  onFiltroTexto(texto) {
    if (this.filtros) this.filtros.texto = texto;
    this.onUpdate().subscribe(_ => { });
  }

  onFiltroEstado(marcado, estado) {
    if (marcado) this.filtros.estado = estado;
    else delete this.filtros.estado;
    this.onUpdate().subscribe(_ => { });
  }

  onFiltroAsignacion(marcado, estado) {
    if (marcado) this.filtros.asignacion = estado;
    else delete this.filtros.asignacion;
    this.onUpdate().subscribe(_ => { });
  }

  onFiltroUsuario() {
    if (this.filtroUsuario) {
      this.filtros.usuario = this.filtroUsuario;
    } else {
      delete this.filtros.usuario;
    }
    this.onUpdate().subscribe(_ => { });
  }

  onFiltroFecha() {
    if (this.filtroFecha) {
      this.filtros.fechaDesde = dateToFechaDesdeISOString(this.filtroFecha);
      this.filtros.fechaHasta = dateToFechaHastaISOString(this.filtroFecha);
    }
    this.onUpdate().subscribe(_ => { });
  }

  onFiltroSinRutina(esSinRutina) {
    if (esSinRutina) this.filtros.esSinRutina = true;
    else delete this.filtros.esSinRutina;
    this.onUpdate().subscribe(_ => { });
  }

  onPageChanged(paginado: any) {
    this.paginado.paginaSeleccionada = paginado.page;
    this.onUpdate().subscribe(_ => { });
  }  

  public itemsToString(items) {
    return items
      .map(i => {
        return i.nombre;
      })
      .join(",");
  }
}
