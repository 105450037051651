import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";

@Injectable()
export class UsuariosService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/usuarios";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.clientes
    ];
  }

  public getAcciones(item: any): AccionInterface[] {
    let acciones = [];
    /* if (item.estado == 0) {
      acciones.push({
        titulo: "Activar",
        accion: EnumAccion.USUARIO_DESBLOQUEAR,
        icono: "unlock",
        datos: { id: item.id, estado: 0 }
      });
    } else {
      acciones.push({
        titulo: "Desactivar",
        accion: EnumAccion.USUARIO_BLOQUEAR,
        icono: "lock",
        datos: { id: item.id, estado: 0 }
      });
    } */
    /*
    acciones.push({
      titulo: "Editar",
      accion: EnumAccion.USUARIO_EDITAR,
      icono: "pencil-alt",
      datos: { id: item.id }
    });*/

    return acciones;
  }
}
