import { Component, Input, QueryList, ViewChildren } from "@angular/core";
import { IConsumo, IUpdateComponent } from "@app/core/interfaces";
import { ConsumosService } from "@app/core/services";
import { OnUpdateComponent } from "@app/shared/components/on-update.component";
import { Observable, zip } from "rxjs";

@Component({
  selector: "consumo-detalle",
  templateUrl: "consumo-detalle.component.html"
})
export class ConsumoDetalleComponent extends OnUpdateComponent {
  @ViewChildren("onUpdateComponent")
  public onUpdateComponents: QueryList<IUpdateComponent>;

  private _itemId: number;
  get itemId(): number {
    return this._itemId;
  }

  @Input()
  set itemId(itemId: number) {
    this._itemId = itemId;
    this.cargando = true;
    this.cargarDatos().subscribe(_ => {
      this.cargando = false;
    });
  }

  public item:IConsumo;

  constructor(private itemService: ConsumosService) {
    super();
  }

  cargarDatos(): Observable<any> {
    return new Observable(observer => {
      if (this.itemId != null) {
        this.item = null;
        this.cargando = true;
        this.mensaje = "Obteniendo información...";

        this.itemService.getById(this.itemId).subscribe(
          (item: IConsumo) => {
            this.item = item;
            this.cargando = false;
            this.mensaje = "";
            let updates = this.onUpdateComponents.map(component => {
              return component.onUpdate();
            });
            if (updates.length > 0) {
              return zip(...updates).subscribe(
                _ => {
                  observer.next();
                  observer.complete();
                },
                error => {
                  observer.error();
                }
              );
            } else {
              observer.next();
              observer.complete();
            }
          },
          error => {
            this.item = null;
            this.cargando = false;
            (this.mensaje = error.error || "Error cargando datos."),
              observer.error();
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }
}
