import { Component } from "@angular/core";
import { AsignacionesService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { IConsumo } from "@app/core/interfaces";

@Component({
  selector: "asignaciones-listado",
  templateUrl: "component.html"
})
export class AsignacionesListado extends ListadoBaseComponent<IConsumo> {
  constructor(protected service: AsignacionesService) {
    super(service);
  }
}
