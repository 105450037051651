import { Component, Input, ViewChildren, QueryList } from "@angular/core";
import { EntidadService, CategoriaEntidadService, RibbonService } from "@app/core/services";
import { Observable, zip } from "rxjs";
import { IEntidad, IUpdateComponent, AccionInterface } from "@app/core/interfaces";
import { OnUpdateComponent } from "@app/shared/components/on-update.component";
import { environment } from "@env/environment";
import { EnumAccion } from "@app/core/enums";

@Component({
  selector: "entidad-detalle",
  templateUrl: "component.html"
})
export class EntidadDetalle extends OnUpdateComponent {
  @ViewChildren("onUpdateComponent")
  public onUpdateComponents: QueryList<IUpdateComponent>;

  private acciones: AccionInterface[] = [];

  @Input() visibilidad = 1;

  private _entidadId: number;
  get entidadId(): number {
    return this._entidadId;
  }

  @Input()
  set entidadId(entidadId: number) {
    if (entidadId != null) {
      this._entidadId = entidadId;
      this.cargando = true;
      this.mensaje = "Obteniendo información del cliente...";
      this.cargarDatos().subscribe(
        _ => {
          this.acciones = [
            {
              titulo: "Editar",
              accion: EnumAccion.ENTIDAD_EDITAR,
              icono: "pencil-alt",
              datos: { entidad: this._entidadId }
            },
            {
              titulo: "Consumo",
              accion: EnumAccion.CONSUMO_CREAR,
              icono: "plus",
              datos: { entidad: this._entidadId }
            },
            {
              titulo: " Suscripción",
              accion: EnumAccion.SUSCRIPCION_CREAR,
              icono: "plus",
              datos: { entidad: this._entidadId }
            },
            {
              titulo: "Contacto",
              accion: EnumAccion.CONTACTO_CREAR,
              icono: "plus",
              datos: { entidad: this._entidadId }
            },
            {
              titulo: "Documento",
              accion: EnumAccion.DOCUMENTO_CREAR,
              icono: "plus",
              datos: { entidad: this._entidadId }
            },
            {
              titulo: "Comentario",
              accion: EnumAccion.COMENTARIO_CREAR,
              icono: "plus",
              datos: { entidad: this._entidadId }
            }
          ];
          this.cargando = false;
        },
        _ => {
          this.cargando = false;
        }
      );
    }
  }

  public entidad: IEntidad;

  public categorias: any[];
  public categoriasIds: number[];

  constructor(
    private entidadService: EntidadService,
    private categoriaEntidadService: CategoriaEntidadService,
    private ribbonService:RibbonService
  ) {
    super();
  }

  cargarDatos(): Observable<any> {
    return new Observable(observer => {
      if (this.entidadId != null) {
        this.categoriaEntidadService.get().subscribe(categorias => {
          this.categorias = categorias.listado;
          this.categoriasIds = this.categorias.map(c => {
            return c.id;
          });

          this.entidadService.getById(this.entidadId).subscribe(
            (entidad: IEntidad) => {
              this.entidad = entidad;
              this.categorias.map(categoria => {
                let activa = entidad.categorias.find(eCategoria => {
                  return eCategoria.id == categoria.id;
                });
                return (categoria.activa = activa);
              });
              this.entidad._categoria = this.entidad.categorias
                .map(c => {
                  return c.nombre;
                })
                .join(" | ");
              let updates = this.onUpdateComponents.map(component => {
                return component.onUpdate();
              });

              if (updates.length > 0) {
                return zip(...updates).subscribe(
                  _ => {
                    observer.next();
                    observer.complete();
                  },
                  error => {
                    observer.error();
                  }
                );
              } else {
                observer.next();
                observer.complete();
              }
            },
            error => {
              this.entidad = null;
              this.error = true;
              (this.mensaje = error.error || "Error cargando datos."),
                observer.error();
            }
          );
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  onCategoriaSelected(categoria) {
    this.entidadService
      .update(this.entidad.id, {
        categorias: [categoria.id]
      })
      .subscribe(_ => { });
  }
}
