import { Pipe } from "@angular/core";
import { DiccionarioBasePipe } from "./diccionario.base.pipe";

@Pipe({
  name: "estadoEntidadDiccionario",
  pure: false
})
export class EstadoEntidadDiccionarioPipe extends DiccionarioBasePipe {
  protected diccionario;

  constructor() {
    super();
    this.diccionario = {
      0: "No Activo",
      1: "Activo"
    };
  }
}
