import { NgModule } from "@angular/core";
import { UtilsModule } from "../utils/utils.module";
import { AccionesModule } from "../acciones/acciones.module";
import { PipesModule } from "../pipes/pipes.module";

import { SuscripcionesListadoTabla } from "./suscripciones/listado-tabla/component";
import { SuscripcionesListado } from "./suscripciones/listado/suscripciones-listado.component";
import { SuscripcionDetalle } from "./suscripciones/detalle/suscripciones-detalle.component";
import { AsignacionesListado } from "./asignaciones/listado/component";
import { ComentariosListado } from "./comentarios/listado/component";
import { DocumentosListado } from "./documentos/listado/component";
import { EntidadesListadoComponent } from "./entidad/listado/entidades-listado.component";
import { EntidadDatosGenerales } from "./entidad/datos-generales/component";
import { EntidadDetalle } from "./entidad/detalle/component";
import { ConsumosListadoRecepcion } from "./consumos/listado-recepcion/component";
import { ConsumosListadoTabla } from "./consumos/listado-tabla/component";
import { ConsumosListadoComponent } from "./consumos/listado/consumos-listado.component";
import { ConsumoDetalleComponent } from "./consumos/consumo-detalle/consumo-detalle.component";
//import { VentasListado } from "./ventas/listado-grupo/ventas-listado-grupo.component";
import { VentasListadoTabla } from "./ventas/listado-tabla/component";
import { VentasListado } from "./ventas/listado/ventas-listado.component";
import { VentasDetalle } from "./ventas/detalle/venta-detalle.component";
import { UsuariosListadoComponent } from "./usuarios/listado/usuarios-listado.component";
import { ProductosListadoComponent } from "./productos/listado/productos-listado.component";

import { PaginationModule } from 'ngx-bootstrap/pagination'

@NgModule({
  imports: [
    UtilsModule,
    PaginationModule,
    AccionesModule,
    PipesModule
  ],
  declarations: [
    EntidadesListadoComponent,
    EntidadDatosGenerales,
    EntidadDetalle,
    ConsumosListadoRecepcion,
    ConsumosListadoTabla,
    ComentariosListado,
    ProductosListadoComponent,
    AsignacionesListado,
    DocumentosListado,
    VentasListado,
    VentasListadoTabla,
    VentasDetalle,
    SuscripcionesListadoTabla,
    SuscripcionesListado,
    SuscripcionDetalle,
    UsuariosListadoComponent,
    ConsumosListadoComponent,
    ConsumoDetalleComponent
  ],
  exports: [
    EntidadesListadoComponent,
    EntidadDatosGenerales,
    EntidadDetalle,
    ConsumosListadoRecepcion,
    ConsumosListadoTabla,
    DocumentosListado,
    VentasListado,
    VentasListadoTabla,
    VentasDetalle,
    AsignacionesListado,
    ComentariosListado,
    SuscripcionesListadoTabla,
    ProductosListadoComponent,
    UsuariosListadoComponent,
    ConsumosListadoComponent,
    ConsumoDetalleComponent,
    SuscripcionesListado,
    SuscripcionDetalle
  ]
})
export class EntidadesModule {}
