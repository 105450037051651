import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InlineEditorModule } from "ng2-inline-editor";

/* OTAS LIBS */
import { AvatarModule } from "ngx-avatar";
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';

/* BOOTSTRAP */
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

/* UTILS */
import { ContentLoadingComponent } from "./content-loading/component";
import { DrawerComponent } from "./drawer/drawer.component";
import { FiltrosComponent } from "./filter-bar/filter-bar.component";
import { UtilsFormsModule } from "./forms/module";
import { UpdatableComponent } from "./updatable-component/updatable-component.component";
import { ErrorsComponent } from "./errors/component";

 
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


@NgModule({
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule, 
    CarouselModule,
    ButtonsModule,
    BsDatepickerModule.forRoot(),
    ProgressbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({ timeOut: 10000, closeButton: true, progressBar: true }),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    AvatarModule.forRoot(),
    InlineEditorModule,
    SwiperModule,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  declarations: [
    DrawerComponent,
    ContentLoadingComponent,
    UpdatableComponent,
    FiltrosComponent,
    ErrorsComponent
  ],
  exports: [    
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    ButtonsModule,
    AlertModule,
    TabsModule,
    TooltipModule,
    BsDropdownModule,
    ProgressbarModule,
    AccordionModule,
    CarouselModule,
    AvatarModule,
    ToastrModule,
    BsDatepickerModule,
    ModalModule,
    SwiperModule,
    DrawerComponent,    
    ContentLoadingComponent,
    UtilsFormsModule,
    UpdatableComponent,
    FiltrosComponent,
    ErrorsComponent
  ]
})
export class UtilsModule {}
