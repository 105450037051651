import { Component, OnInit } from "@angular/core";
import { IConsumo } from "@app/core/interfaces";
import { ConsumosService, CategoriaEntidadService, AppService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "consumos-listado",
  templateUrl: "consumos-listado.component.html"
})
export class ConsumosListadoComponent extends ListadoBaseComponent<IConsumo> implements OnInit  {

  public filtrosVisibilidad = [
    'asignacion', 'fecha', 'categoria', 'esSinRutina'
  ]

  public filtrosEntidadCategorias = [];

  public ngOnInit() {
    this.categoriaEntidadService.get({ estado: 1 }).subscribe(categorias=>{
      this.filtrosEntidadCategorias = categorias.listado.map(categoria=>{
        return {
          nombre : categoria.nombre,
          valor : categoria.id
        }
      })
    });
  }

  constructor(protected consumosService: ConsumosService, public categoriaEntidadService : CategoriaEntidadService, public appService:AppService) {
    super(consumosService);
  }

  public hasPermision(permision:string):boolean{
    return this.appService.hasPermision(permision);
  }
}
