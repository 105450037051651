import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface, IModulo } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ConsumosService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/consumos";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.clientes,
      this.appService.servicios.ingresos
    ];
  }

  public create(datos: any): Observable<any> {
    return this.apiService.post(`${this.entityUrl}`, datos).pipe(
      map(
        res => {
          this.servicios.forEach((servicio: BehaviorSubject<IModulo>) => {
            let servicioNuevo = servicio.value;
            servicioNuevo.ultimaActualizacion = new Date();
            servicio.next(servicioNuevo);
          });
          return res;
        }
      )
    );
  }

  public getAcciones(item: any): AccionInterface[] {
    return [
      {
        titulo: "Registrar Venta",
        accion: EnumAccion.VENTA_CREAR,
        icono: "cart-plus",
        datos: { entidad: item.entidad.id }
      },
      {
        titulo: "Eliminar",
        accion: EnumAccion.CONSUMO_ELIMINAR,
        icono: "trash",
        datos: { id: item.id, estado: 0 }
      }
       /* ,
      {
        titulo: "Editar",
        accion: EnumAccion.CONSUMO_EDITAR,
        icono: "pencil-alt",
        datos: { id: item.id }
      } */
    ];
  }
}
