import { Component } from "@angular/core";
import { EntidadService, ProductosService, CategoriaEntidadService, AppService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { IEntidad } from "@app/core/interfaces";
import { LayoutService } from "@app/shared/layout/layout.service";

@Component({
  selector: "entidades-listado",
  templateUrl: "entidades-listado.component.html",
  providers: [LayoutService]
})
export class EntidadesListadoComponent extends ListadoBaseComponent<IEntidad> {
  
  public filtrosVisibilidad = [
    'estado', 'fecha', 'categoria', 'esSinRutina', 'suscripto'
  ]

  public filtroEstados = [
    {
      nombre: "Inactivos",
      valor: 0
    },
    {
      nombre: "Activos",
      valor: 1
    }
  ]

  public filtrosEntidadCategorias = [];
  public filtrosProductos = [];

  constructor(
    protected service: EntidadService,
    public categoriaEntidadService : CategoriaEntidadService,
    public productosService: ProductosService,
    public appService:AppService
  ) {
    super(service);
  }

  public ngOnInit() {
    this.categoriaEntidadService.get({ estado: 1 }).subscribe(categorias=>{
      this.filtrosEntidadCategorias = categorias.listado.map(categoria=>{
        return {
          nombre : categoria.nombre,
          valor : categoria.id
        }
      })
    });

    this.productosService.get({ estado: 1, esSuscribible:true }).subscribe(productos=>{
      this.filtrosProductos = productos.listado.map(producto=>{
        return {
          nombre : producto.nombre,
          valor : producto.id
        }
      })
    });
  }

  public hasPermision(permision:string):boolean{
    return this.appService.hasPermision(permision);
  }
}
