import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class AccionesService {
  public nuevaAccion: any;

  constructor() {
    this.nuevaAccion = new Subject();
  }

  iniciarAccion(accion: any) {
    this.nuevaAccion.next(accion);
  }
}
