import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { fromEvent } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

declare var $: any;
interface HTMLDocument extends Document {
  fullscreen : boolean
}
declare var document: HTMLDocument;

@Injectable()
export class LayoutService {
  public layout: {
    menuCollapsed: boolean;
    menuGrupoListadoCollapsed: boolean,
    mobileView: boolean
  };

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.inicializarLayout();
    this.processBody(this.layout);
    
    fromEvent(window, "resize")
      .pipe(
        debounceTime(100),
        map(() => {
          this.processBody(this.layout, { mobileMenuCollapsed: true, mobileMenuGrupoListadoCollapsed:true });
        })
      )
      .subscribe();
  }

  get isFullscreenActived():boolean{
    return document.fullscreen
  }

  inicializarLayout() {
    let mobileView = this.isMobileView();
    this.layout = {
      mobileView: mobileView,
      menuCollapsed: mobileView,
      menuGrupoListadoCollapsed: mobileView
    }
  }

  isMobileView():boolean{
    return window.innerWidth < 1200;
  }

  onCollapseMenuToggle(value?) {
    if (typeof value !== "undefined") {
      this.layout.menuCollapsed = value;
    } else {
      this.layout.menuCollapsed = !this.layout.menuCollapsed;
    }
    this.processBody(this.layout);
  }

  onCollapseMenuGrupoListado(value?) {
    if (typeof value !== "undefined") {
      this.layout.menuGrupoListadoCollapsed = value;
    } else {
      this.layout.menuGrupoListadoCollapsed = !this.layout
        .menuGrupoListadoCollapsed;
    }
    this.processBody(this.layout);
  }

  onFullScreenToggle() {
    if (this.isFullscreenActived) {
      document.exitFullscreen();
    } else {
      document.querySelector("body").requestFullscreen();
    }
  }

  processBody(state, options: { mobileMenuCollapsed?: boolean, mobileMenuGrupoListadoCollapsed?:boolean } = {}) {
    this.layout.mobileView = this.isMobileView();
    this.layout.menuCollapsed = (Object.keys(options).some(key => key == "mobileMenuCollapsed")) ? (options.mobileMenuCollapsed && this.layout.mobileView) : this.layout.menuCollapsed;
    this.layout.menuGrupoListadoCollapsed = (Object.keys(options).some(key => key == "mobileMenuGrupoListadoCollapsed")) ? (options.mobileMenuGrupoListadoCollapsed && this.layout.mobileView) : this.layout.menuGrupoListadoCollapsed;

    if (!this.layout.mobileView) {
      this.renderer.removeClass(document.body, "mobile-view");
    } else {
      this.renderer.addClass(document.body, "mobile-view");
    }

    if (!this.layout.menuCollapsed) {
      this.renderer.removeClass(document.body, "hidden-navigation");
    } else {
      this.renderer.addClass(document.body, "hidden-navigation");
    }

    if (!this.layout.menuGrupoListadoCollapsed) {
      this.renderer.removeClass(document.body, "hidden-grupo-navigation");
    } else {
      this.renderer.addClass(document.body, "hidden-grupo-navigation");
    }

    if (this.isFullscreenActived) {
      this.renderer.addClass(document.body, "fullscreen");
    } else {
      this.renderer.removeClass(document.body, "fullscreen");
    }    
  }
}
