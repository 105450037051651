import { Pipe } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "contacto",
  pure: false
})
export class ContactoDiccionarioPipe {
  protected diccionario;

  constructor(private sanitized: DomSanitizer) {
    this.diccionario = {
      0: "<i class='fas fa-envelope'></i> Email",
      1: "<i class='fas fa-phone'></i> Teléfono",
      2: "<i class='fas fa-mobile-alt'></i> Celular",
      3: "<i class='fa fa-facebook-square'></i> Facebook",
      4: "<i class='fa fa-instagram'></i> Instagram"
    };
  }

  transform(item: number): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(
      this.diccionario[item] || "Otro"
    );
  }
}
