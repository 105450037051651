import { Component, OnInit, Input } from "@angular/core";
import { AccionInterface } from "../../core/interfaces/accion.interface";

@Component({
  selector: "accion-grupo",
  templateUrl: "./accion-grupo.component.html"
})
export class AccionGrupoComponent implements OnInit {
  @Input()
  acciones: AccionInterface[] = [];

  @Input()
  tipo: number = 1; // 1 responsive, 2 inline, 3 dropdown

  constructor() {}

  ngOnInit() {}
}
