import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContactosService } from "@app/core/services";
import { EnumTipoContacto } from "@app/core/enums";

@Component({
  selector: "contacto-crear",
  templateUrl: "./component.html"
})
export class ContactoCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public entidadId;

  public valuesTipoContacto = EnumTipoContacto.values();

  constructor(
    private contactosService: ContactosService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit() {}

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
    this.entidadId = null;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();
    this.entidadId = accion.datos.entidad;
    this.form = this.formBuilder.group({
      tipo: [EnumTipoContacto.EMAIL, Validators.required],
      valor: ["", Validators.required]
    });
    this.cargando = false;
    this.modal = this.modalService.show(this.template);
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;
    console.log(this.f);

    if (this.form.invalid) {
      return;
    }

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};
    let contacto = {
      entidad: this.entidadId,
      tipo: this.f.tipo.value,
      valor: this.f.valor.value
    };

    this.contactosService.create(contacto).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
