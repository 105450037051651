import { Component, OnInit, Input } from "@angular/core";
import { AppService } from "../../../core/services";
import { IUsuarioAutenticado } from "../../../core/interfaces";

@Component({
  selector: "user-info",
  templateUrl: "./user-info.component.html"
})
export class UserInfoComponent implements OnInit {
  public usuario: IUsuarioAutenticado;
  public usuarioSub;

  constructor(private appService: AppService) {
    this.usuario = this.appService.usuario$.value;
  }

  ngOnInit() {
    this.usuario = this.appService.usuario$.value;
    this.usuarioSub = this.appService.usuario$.subscribe(
      (usuario: IUsuarioAutenticado) => (this.usuario = usuario)
    );
  }

  ngOnDestroy() {
    if (this.usuarioSub) this.usuarioSub.unsubscribe();
  }
}
