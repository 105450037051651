import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RolDiccionarioPipe } from "./rol.diccionario.pipe";
import { SiONoDiccionarioPipe } from "./siono.diccionario.pipe";
import { ContactoDiccionarioPipe } from "./contacto.diccionario.pipe";
import { FormaDePagoDiccionarioPipe } from "./forma-de-pago.diccionario.pipe";
import { EstadoEntidadDiccionarioPipe } from "./estado-entidad.diccionario.pipe";
import { TipoDocumentoDiccionarioPipe } from "./tipo-documento.diccionario.pipe";
import { EstadoUsuarioPipe } from "./estado-usuario.pipe";
import { EstadoProductoPipe } from "./estado-producto.pipe";
import { EstadoEntidadPipe } from "./estado-entidad.pipe";
import { EstadoAsignacionPipe } from "./estado-asignacion.pipe";
import { EstadoSuscripcionPipe } from "./estado-suscripcion.pipe";
import { EstadoVentaPipe } from "./estado-venta.pipe";
import { AsignacionConsumoPipe } from "./estado-consumo.pipe";
import { TipoRepeticionDiccionarioPipe } from "./tipo-repeticion.diccionario.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    SiONoDiccionarioPipe,
    RolDiccionarioPipe,
    EstadoEntidadDiccionarioPipe,
    ContactoDiccionarioPipe,
    FormaDePagoDiccionarioPipe,
    TipoDocumentoDiccionarioPipe,
    EstadoUsuarioPipe,
    EstadoProductoPipe,
    AsignacionConsumoPipe,
    EstadoEntidadPipe,
    EstadoAsignacionPipe,
    EstadoSuscripcionPipe,
    EstadoVentaPipe,
    TipoRepeticionDiccionarioPipe
  ],
  exports: [
    SiONoDiccionarioPipe,
    RolDiccionarioPipe,
    EstadoEntidadDiccionarioPipe,
    ContactoDiccionarioPipe,
    FormaDePagoDiccionarioPipe,
    TipoDocumentoDiccionarioPipe,
    EstadoUsuarioPipe,
    EstadoProductoPipe,
    AsignacionConsumoPipe,
    EstadoEntidadPipe,
    EstadoAsignacionPipe,
    EstadoSuscripcionPipe,
    EstadoVentaPipe,
    TipoRepeticionDiccionarioPipe
  ]
})
export class PipesModule {}
