import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UtilsModule } from './../utils/utils.module'

import { AccionesModule } from "../acciones/acciones.module";
import { EntidadesModule } from "../components/entidades.module";

import { MainLayoutComponent } from "./app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./app-layouts/auth-layout.component";
import { FooterComponent } from "./footer/footer.component";
import { NavigationModule } from "./navigation/navigation.module";
import { HeaderContentComponent } from "./header-content/header-content.component";

@NgModule({
  imports: [
    RouterModule,
    UtilsModule,
    AccionesModule,
    EntidadesModule,
    NavigationModule
  ],
  declarations: [
    MainLayoutComponent,
    AuthLayoutComponent,
    HeaderContentComponent,
    FooterComponent,
  ],
  exports: []
})
export class WebAppLayoutModule { }
