import { AccionesService } from "./acciones.service";
import { ApiService } from "./api.service";
import { AppService } from "./app.service";
import { AutenticacionService } from "./autenticacion.service";
import { RibbonService } from "./ribbon.service";
import { SocketService } from "./socket.service";
import { ReportesService } from "./reportes.service";

import { AsignacionesService } from "./entidades/asignaciones.service";
import { ConsumiblesService } from "./entidades/consumibles.service";
import { ConsumosService } from "./entidades/consumos.service";
import { EntidadService } from "./entidades/entidad.service";
import { UsuariosService } from "./entidades/usuarios.service";
import { DocumentosService } from "./entidades/documentos.service";
import { ComentariosService } from "./entidades/comentarios.service";
import { ProductosService } from "./entidades/productos.service";
import { VentasService } from "./entidades/ventas.service";
import { CategoriaEntidadService } from "./entidades/categorias-entidad.service";
import { DescuentosProductoService } from "./entidades/descuentos-producto..service";
import { SuscripcionesService } from "./entidades/suscripciones.service";
import { ContactosService } from "./entidades/contactos.service";

export const services = [
  AccionesService,
  ApiService,
  AppService,
  AutenticacionService,
  RibbonService,
  AsignacionesService,
  ConsumiblesService,
  ConsumosService,
  EntidadService,
  UsuariosService,
  DocumentosService,
  ComentariosService,
  ProductosService,
  VentasService,
  CategoriaEntidadService,
  DescuentosProductoService,
  SuscripcionesService,
  ContactosService,
  SocketService,
  ReportesService
];

export * from "./acciones.service";
export * from "./api.service";
export * from "./app.service";
export * from "./autenticacion.service";
export * from "./ribbon.service";
export * from "./socket.service";
export * from "./reportes.service";
export * from "./entidades/asignaciones.service";
export * from "./entidades/consumibles.service";
export * from "./entidades/consumos.service";
export * from "./entidades/entidad.service";
export * from "./entidades/usuarios.service";
export * from "./entidades/comentarios.service";
export * from "./entidades/documentos.service";
export * from "./entidades/productos.service";
export * from "./entidades/ventas.service";
export * from "./entidades/categorias-entidad.service";
export * from "./entidades/descuentos-producto..service";
export * from "./entidades/suscripciones.service";
export * from "./entidades/contactos.service";
