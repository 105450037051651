import { Component, ElementRef, OnInit } from "@angular/core";
import { IUsuarioAutenticado } from "../../../core/interfaces";
import { AppService } from "../../../core/services";
import { LayoutService } from "../layout.service";
import { environment } from "@env/environment.prod";

@Component({
  selector: "eg-navigation",
  templateUrl: "./navigation.component.html"
})
export class NavigationComponent implements OnInit {
  public instancia = environment.instancia;

  public permisos = [];

  public usuarioSub;
  public servicioSub;

  public usuario: IUsuarioAutenticado;

  constructor(private appService: AppService, private _elementRef: ElementRef, private layoutService: LayoutService) { }

  ngOnInit() {
    this.appService.usuario$.subscribe((usuario: IUsuarioAutenticado) => {
      this.permisos = usuario.permisos;
    });
  }

/*   @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this._elementRef.nativeElement.contains(targetElement)) {

    }
  } */

  itemClicked() {
    if (this.layoutService.layout.mobileView) this.layoutService.onCollapseMenuToggle(true);
  }

  canShowItem(permiso: string): boolean {
    return this.permisos.find(item => item === permiso);
  }
}
