import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EnumFormaDePago } from "@app/core/enums";
import { VentasService } from "@app/core/services";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";

@Component({
  selector: "venta-crear-from-file",
  templateUrl: "./component.html"
})
export class VentaCrearFromFileComponent implements OnInit {
  public modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public valuesFormasDePago = EnumFormaDePago.values();

  constructor(
    private ventasService: VentasService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) { }

  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();
    var date = new Date();
    var ultimoDia = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
      23,
      59
    );

    let month = date.getMonth().toString();
    month = date.getMonth() < 10 ? "0" + month : month;

    this.form = this.formBuilder.group({
      validoDesdeDia: ["01", Validators.required],
      validoDesdeMes: [month, Validators.required],
      validoDesdeAnio: [date.getFullYear(), Validators.required],
      validoHastaDia: [ultimoDia.getDate(), Validators.required],
      validoHastaMes: [month, Validators.required],
      validoHastaAnio: [ultimoDia.getFullYear(), Validators.required],
      vigenciaMes: [date.getMonth() + 1, Validators.required],
      vigenciaAnio: [date.getFullYear(), Validators.required],
      formaDePago: [4, Validators.required],
      file: [null, Validators.required],
      enviarConfirmacion: [true, Validators.required]
    });

    this.cargando = false;
    this.modal = this.modalService.show(this.template);
    this.onChanges();
  }

  protected onValidoFecha(mes: number, anio: number) {
    var date = new Date(anio, mes, 1);
    var ultimoDia = new Date(
      date.getFullYear(),
      date.getMonth(),
      0,
      23,
      59,
      59
    );

    let month = date.getMonth().toString();
    month = date.getMonth() < 10 ? "0" + month : month;

    this.f.validoDesdeDia.setValue("01");
    this.f.validoDesdeMes.setValue(month);
    this.f.validoDesdeAnio.setValue(anio);

    this.f.validoHastaDia.setValue(ultimoDia.getDate());
    this.f.validoHastaMes.setValue(month);
    this.f.validoHastaAnio.setValue(anio);
  }

  onChanges(): void {  
    this.form.get("vigenciaMes").valueChanges.subscribe(val => {
      if (val) {
        this.onValidoFecha(this.f.vigenciaMes.value, this.f.vigenciaAnio.value);
      }
    });
    this.form.get("vigenciaAnio").valueChanges.subscribe(val => {
      if (val) {
        this.onValidoFecha(this.f.vigenciaMes.value, this.f.vigenciaAnio.value);
      }
    });
  }

  onFileUpload(files: FileList) {
    this.cargando = true;
    let file = files.item(0);
    let fileReader: FileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = ev => {
        this.form.patchValue({
          file: fileReader.result
        });
        this.cargando = false;
      };
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    let items: any[] = [
      {
        cantidad: 1,
        validoDesde: new Date(`${this.f.validoDesdeAnio.value}-${ this.f.validoDesdeMes.value}-${this.f.validoDesdeDia.value} 00:00:00`),
        validoHasta: new Date(`${this.f.validoHastaAnio.value}-${ this.f.validoHastaMes.value }-${this.f.validoHastaDia.value} 23:59:59`)
      }
    ];

    let venta = {
      file: this.f.file.value.toString(),
      items: items,
      formaDePago: this.f.formaDePago.value,
      enviarConfirmacion: this.f.enviarConfirmacion.value
    };

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};

    this.ventasService.create(venta).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
