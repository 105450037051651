import { Pipe } from "@angular/core";
import { DiccionarioBasePipe } from "./diccionario.base.pipe";

@Pipe({
  name: "tipoDocumento",
  pure: false
})
export class TipoDocumentoDiccionarioPipe extends DiccionarioBasePipe {
  protected diccionario;

  constructor() {
    super();
    this.diccionario = {
      0: "Carné de Salud",
      1: "Ficha Médica"
    };
  }
}
