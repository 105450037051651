import { NgModule } from "@angular/core";
import { UtilsModule } from "../utils/utils.module";

/* ACCIONES */
import { AccionesComponent } from "./acciones.component";
import { AccionComponent } from "./accion.component";
import { AccionGrupoComponent } from "./accion-grupo.component";

/* ACCIONES ENTIDADES */
import { VentaCrearComponent } from "./entidades/venta/crear/component";
import { VentaCrearFromFileComponent } from "./entidades/venta/crear-from-file/component";
import { EntidadCrearComponent } from "./entidades/entidad/crear/entidad-crear.component";
import { EntidadEditarComponent } from "./entidades/entidad/editar/entidad-editar.component";
import { ComentarioCrearComponent } from "./entidades/comentario/crear/component";
import { DocumentoCrearComponent } from "./entidades/documento/crear/documento-crear.component";
import { SuscripcionCrearComponent } from "./entidades/suscripcion/crear/suscripcion-crear.component";
import { ContactoCrearComponent } from "./entidades/contacto/crear/component";
import { ConsumoCrearComponent } from "./entidades/consumo/crear/consumo-crear.component";
import { ConsumoIngresosCrearComponent } from "./entidades/consumo/crear-ingresos/ingresos-crear.component";

@NgModule({
  imports: [
    UtilsModule
  ],
  declarations: [
    AccionComponent,
    AccionGrupoComponent,
    AccionesComponent,
    VentaCrearComponent,
    VentaCrearFromFileComponent,
    EntidadCrearComponent,
    ComentarioCrearComponent,
    DocumentoCrearComponent,
    SuscripcionCrearComponent,
    ContactoCrearComponent,
    ConsumoCrearComponent,
    EntidadEditarComponent,
    ConsumoIngresosCrearComponent
  ],
  exports: [
    AccionesComponent,
    AccionGrupoComponent,
    AccionComponent,
  ]
})
export class AccionesModule {}
