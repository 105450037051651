import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ComentariosService } from "@app/core/services";

@Component({
  selector: "comentario-crear",
  templateUrl: "./component.html"
})
export class ComentarioCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public entidadId;

  constructor(
    private comentariosService: ComentariosService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit() {}

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
    this.entidadId = null;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();
    this.entidadId = accion.datos.entidad;
    this.form = this.formBuilder.group({
      titulo: [""],
      texto: ["", Validators.required]
    });
    this.cargando = false;
    this.modal = this.modalService.show(this.template);
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;
    console.log(this.f);

    if (this.form.invalid) {
      return;
    }

    let nota = {
      entidad: this.entidadId,
      titulo: this.f.titulo.value,
      texto: this.f.texto.value
    };

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};

    this.comentariosService.create(nota).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
