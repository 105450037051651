import { Component, OnInit, Input } from "@angular/core";
import { AccionesService } from "../../core/services/acciones.service";
import { EnumAccion } from "@app/core/enums";

@Component({
  selector: "accion",
  templateUrl: "./accion.component.html"
})
export class AccionComponent implements OnInit {
  @Input()
  accion: EnumAccion;

  @Input()
  datos: any;

  constructor(private accionesService: AccionesService) {}

  ngOnInit() {}

  iniciarAccion() {
    this.accionesService.iniciarAccion({
      accion: this.accion,
      datos: this.datos
    });
  }
}
