import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectEntidadComponent } from "./input/select-entidad/select-entidad.component";
import { SelectEnumeradoComponent } from "./input/select-enum/component";
import { Select2Module } from "./input/select2/select2.module";
import { XEditableComponent } from "./input/x-editable.component";

@NgModule({
  imports: [CommonModule],
  declarations: [SelectEntidadComponent, SelectEnumeradoComponent, XEditableComponent],
  exports: [SelectEntidadComponent, SelectEnumeradoComponent, Select2Module, XEditableComponent]
})
export class UtilsFormsModule { }
