import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EnumTipoDocumento } from "@app/core/enums";
import { DocumentosService } from "@app/core/services";
import { BsLocaleService } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { dateToFechaHasta } from "@app/utils";
import { DatePipe } from "@angular/common";

@Component({
  selector: "documento-crear",
  templateUrl: "./documento-crear.component.html"
})
export class DocumentoCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public entidadId;

  public valuesTipoDocumento = EnumTipoDocumento.values();

  public maxDate = dateToFechaHasta(new Date());

  constructor(
    private documentosService: DocumentosService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    public bsLocaleService: BsLocaleService,
    private datePipe: DatePipe
  ) {
    this.bsLocaleService.use("es");
  }

  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
    this.entidadId = null;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();
    this.entidadId = accion.datos.entidad;
    this.form = this.formBuilder.group({
      tipo: [1, Validators.required],
      fechaExpedicion: [""],
      fechaVencimiento: [""]
    });

    this.cargando = false;
    this.modal = this.modalService.show(this.template);
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;
    console.log(this.f);

    if (this.form.invalid) {
      return;
    }

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};

    let documento = {
      entidad: this.entidadId,
      tipo: this.f.tipo.value,
      fechaExpedicion: this.datePipe.transform(this.f.fechaExpedicion.value, "yyyy-MM-dd 00:00"),
      fechaVencimiento: this.datePipe.transform(this.f.fechaVencimiento.value, "yyyy-MM-dd 00:00"),
    };

    this.documentosService.create(documento).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
