export enum EnumFormaDePago {
  EFECTIVO = 0,
  CREDITO = 1,
  DEBITO = 2,
  CRIPTO = 3,
  MERCADO_PAGO = 4,
  DEBITO_AUTOMATICO = 5,
  OTRA = 99
}


export namespace EnumFormaDePago {
  export function values(): { valor: number; nombre: string }[] {
    return [
      { valor: 0, nombre: "Efectivo" },
      { valor: 1, nombre: "Crédito" },
      { valor: 2, nombre: "Débito" },
      { valor: 3, nombre: "Cripto" },
      { valor: 4, nombre: "Mercado Pago" },
      { valor: 5, nombre: "Débito Automático" },
      { valor: 99, nombre: "Otra" }
    ];
  }
}
