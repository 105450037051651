import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "content-loading",
  templateUrl: "./component.html"
})
export class ContentLoadingComponent implements OnInit {
  @Input()
  mensaje: string;

  @Input()
  cargando: boolean;

  @Input()
  error: boolean;

  constructor() {}

  ngOnInit() {}
}
