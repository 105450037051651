import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { IConsumo, IModulo } from "@app/core/interfaces";
import { AppService, ConsumosService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { Subscription } from "rxjs";

@Component({
  selector: "consumos-listado-recepcion",
  templateUrl: "component.html"
})
export class ConsumosListadoRecepcion extends ListadoBaseComponent<IConsumo> implements OnInit {  
  @Output() entidadSeleccionada: EventEmitter<number> = new EventEmitter();

  public subscriptions: Subscription[] = [];

  constructor(protected consumosService: ConsumosService, public appService: AppService) {
    super(consumosService);
  }

  ngOnInit(){
    this.subscriptions.push(this.appService.servicios.ingresos.subscribe(_=>{
      this.update();
    })); 

    this.subscriptions.push(this.appService.servicios.clientes.subscribe(_=>{
      this.update();
    })); 
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
