import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationComponent } from "./navigation.component";
import { RouterModule } from "@angular/router";
import { UserInfoComponent } from "../user-info/user-info.component";
import { AvatarModule } from "ngx-avatar";

@NgModule({
  imports: [CommonModule, RouterModule, AvatarModule],
  declarations: [NavigationComponent, UserInfoComponent],
  exports: [NavigationComponent]
})
export class NavigationModule {}
