import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConsumosService } from "@app/core/services";
import { EnumFormaDePago } from "@app/core/enums";
import { DatePipe } from "@angular/common";
import { BsLocaleService } from "ngx-bootstrap";
import { dateToFechaHasta } from "@app/utils";

@Component({
  selector: "ingresos-crear",
  templateUrl: "./ingresos-crear.component.html"
})
export class ConsumoIngresosCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public maxDate = dateToFechaHasta(new Date());

  public valuesFormasDePago = EnumFormaDePago.values();

  constructor(
    private consumosService: ConsumosService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public bsLocaleService: BsLocaleService
  ) {
    this.bsLocaleService.use("es");
  }

  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = false;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
    if (this.form) this.form.controls.entidad.setValue("");
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();

    let hora = `${new Date().getHours().toString()}:00`;

    this.form = this.formBuilder.group({
      entidad: ["", Validators.required],
      fecha: [this.datePipe.transform(new Date(), "yyyy-MM-dd"), Validators.required],
      hora: [hora, Validators.required]
    });

    this.cargando = false;
    this.modal = this.modalService.show(this.template);
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    let consumo = {
      entidad: this.f.entidad.value,
      cantidad: 1,
      consumible: 1,
      fecha: new Date(`${this.datePipe.transform(this.f.fecha.value, "yyyy-MM-dd")} ${this.f.hora.value}`)
    };

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};

    this.consumosService.create({ consumos: [consumo] }).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.limpiarFormulario();
      },
      (error: any) => {
        this.cargando = false;
        this.limpiarFormulario();
        this.error = error;
      }
    );
  }
}
