import { Component } from "@angular/core";
import { ComentariosService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { IComentario } from "@app/core/interfaces";

@Component({
  selector: "comentarios-listado",
  templateUrl: "component.html"
})
export class ComentariosListado extends ListadoBaseComponent<IComentario> {
  constructor(protected service: ComentariosService) {
    super(service);
  }
}
