import { Component, Input, EventEmitter, Output, TemplateRef } from "@angular/core";
import { FiltroInterface, UsuarioInterface, FiltroNameInterface } from "@app/core/interfaces";
import { isEmpty, dateToFechaDesdeISOString, dateToFechaHastaISOString, getYesterday, getFirtDayOfYear, getFirtDayOfWeek, getFirtDayOfMonth, dateToFechaHasta } from "@app/utils";
import { AppService } from "@app/core/services";
import { EnumFormaDePago } from "@app/core/enums";
import { BsLocaleService, BsModalRef, BsModalService } from "ngx-bootstrap";
import { DatePipe } from "@angular/common";

declare var $: any;
@Component({
  selector: "filter-bar",
  templateUrl: "./filter-bar.component.html",
})
export class FiltrosComponent {
  @Input()
  private filtros: FiltroInterface = {};
  
  @Input()
  estados: any = [
    {
      nombre: "No Activo",
      valor: 0
    },
    {
      nombre: "Activo",
      valor: 1
    }
  ]

  @Input()
  usuarios : UsuarioInterface[] = [];
  
  @Input()
  asignaciones: any = [{
    nombre: "Pendientes",
    valor: false
  },
  {
    nombre: "Asignados",
    valor: true
  }]
  
  @Input()
  esSinRutinas: any = [{
    nombre: "Es Sin Rutina",
    valor: true
  },
  {
    nombre: "No es Sin Rutina",
    valor: false
  }]
  
  @Input()
  formasDePago = EnumFormaDePago.values();
  
  @Input()
  categorias = [];
  
  @Input()
  suscriptoProductos = [];
  
  @Input()
  public usuario: UsuarioInterface;
  
  @Input()
  visibilidad: string[] = [
    'estado', 'fecha'
  ]
  
  @Output() filtrosChange: EventEmitter<FiltroInterface> = new EventEmitter();
  private _filters: FiltroInterface = {};
  private _filtersExtra: FiltroNameInterface = {};

  public modalRef: BsModalRef;
  public _fechasForm = {
    fechaDesde: null,
    fechaHasta: null
  }

  constructor(public appService: AppService, private datePipe: DatePipe, private bsLocaleService: BsLocaleService, private modalService: BsModalService) {
    this.bsLocaleService.use("es");
  }

  openModal(template: TemplateRef<any>) {
    if (this._filters.fechaDesde != null) {
      this._fechasForm.fechaDesde = this.datePipe.transform(this._filters.fechaDesde, "yyyy-MM-dd 00:00")
    }else{
      this._fechasForm.fechaDesde = null;
    }

    if (this._filters.fechaHasta != null) {
      this._fechasForm.fechaHasta = this.datePipe.transform(this._filters.fechaDesde, "yyyy-MM-dd 23:59")
    }else{
      this._fechasForm.fechaHasta = new Date();
    }
    this.modalRef = this.modalService.show(template);
  }

  get hasFilters(): boolean {
    return !isEmpty(this._filters);
  }

  get filterNames():any{
    return Object.keys(this._filtersExtra);
  }

  isVisible(filtros): boolean {
    return this.visibilidad.includes(filtros);
  }

  removeFiltro(filtro: string) {
    delete this._filters[filtro];
    delete this._filtersExtra[filtro];
    this.onfiltrosChange();
  }

  removeFiltroFechas() {
    delete this._filters.fechaDesde;
    delete this._filters.fechaHasta;
    delete this._filtersExtra.fechaDesde;
    delete this._filtersExtra.fechaHasta;
    this.onfiltrosChange();
  }

  cleanFiltros(filtro: string) {
    this._filters = {};
    this._filtersExtra = {};
    this.onfiltrosChange();
  }

  addFiltro(filtro: any, valor: any, texto:any = valor) {
    let hoy = new Date();
    switch (valor) {
      case "MI_USUARIO":
        this._filters[filtro] = this.appService.usuario$.value.id;
        this._filtersExtra[filtro] = this.appService.usuario$.value.nombre;
        break;
      case "FECHA_HOY":
        this._filters.fechaDesde = dateToFechaDesdeISOString(hoy);
        this._filters.fechaHasta = dateToFechaHastaISOString(hoy);
        this._filtersExtra.fechaDesde = `Desde ${this.datePipe.transform(new Date(this._filters.fechaDesde), "yyyy-MM-dd")}`;
        this._filtersExtra.fechaHasta = `Hasta ${this.datePipe.transform(new Date(this._filters.fechaHasta), "yyyy-MM-dd")}`;
        break;
      case "FECHA_AYER":
        let ayer = getYesterday(new Date());
        this._filters.fechaDesde = dateToFechaDesdeISOString(ayer);
        this._filters.fechaHasta = dateToFechaHastaISOString(ayer);
        this._filtersExtra.fechaDesde = `Desde ${this.datePipe.transform(new Date(this._filters.fechaDesde), "yyyy-MM-dd")}`;
        this._filtersExtra.fechaHasta = `Hasta ${this.datePipe.transform(new Date(this._filters.fechaHasta), "yyyy-MM-dd")}`;
        break;
      case "FECHA_ESTA_SEMANA":
        let lunes = getFirtDayOfWeek(new Date());
        this._filters.fechaDesde = dateToFechaDesdeISOString(lunes);
        this._filters.fechaHasta = dateToFechaHastaISOString(hoy);
        this._filtersExtra.fechaDesde = `Desde ${this.datePipe.transform(new Date(this._filters.fechaDesde), "yyyy-MM-dd")}`;
        this._filtersExtra.fechaHasta = `Hasta ${this.datePipe.transform(new Date(this._filters.fechaHasta), "yyyy-MM-dd")}`;
        break;
      case "FECHA_ESTE_MES":
        let unoMes = getFirtDayOfMonth(new Date());
        this._filters.fechaDesde = dateToFechaDesdeISOString(unoMes);
        this._filters.fechaHasta = dateToFechaHastaISOString(hoy);
        this._filtersExtra.fechaDesde = `Desde ${this.datePipe.transform(new Date(this._filters.fechaDesde), "yyyy-MM-dd")}`;
        this._filtersExtra.fechaHasta = `Hasta ${this.datePipe.transform(new Date(this._filters.fechaHasta), "yyyy-MM-dd")}`;
        break;
      case "FECHA_ESTE_ANIO":
        let unoEnero = getFirtDayOfYear(new Date());
        this._filters.fechaDesde = dateToFechaDesdeISOString(unoEnero);
        this._filters.fechaHasta = dateToFechaHastaISOString(hoy);
        this._filtersExtra.fechaDesde = `Desde ${this.datePipe.transform(new Date(this._filters.fechaDesde), "yyyy-MM-dd")}`;
        this._filtersExtra.fechaHasta = `Hasta ${this.datePipe.transform(new Date(this._filters.fechaHasta), "yyyy-MM-dd")}`;
        break;
      default:
        this._filters[filtro] = valor;
        this._filtersExtra[filtro] = texto;
        break;
    }
    this.onfiltrosChange();
  }

  onfiltrosChange() {
    this.filtrosChange.emit({ ...this._filters, ...this.filtros });
  }

  public aceptarFiltrosFechasForm() {
    if (this._fechasForm.fechaDesde != null) {
      this._filters.fechaDesde = dateToFechaDesdeISOString(new Date(this._fechasForm.fechaDesde));
    }

    if (this._fechasForm.fechaHasta != null) {
      this._filters.fechaHasta = dateToFechaHastaISOString(new Date(this._fechasForm.fechaHasta));
    }

    this.onfiltrosChange();
    this.modalRef.hide();
  }
}
