import { Component, Input } from "@angular/core";
import { VentasService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { VentaInterface } from "@app/core/interfaces";
import { LayoutService } from "@app/shared/layout/layout.service";

@Component({
  selector: "ventas-listado-tabla",
  templateUrl: "component.html",
  providers: [LayoutService]
})
export class VentasListadoTabla extends ListadoBaseComponent<VentaInterface> {
  @Input()
  public mostrarAccionDeRegistro : boolean = true;

  constructor(protected service: VentasService) {
    super(service);
  }

  public itemsToString(items) {
    return items
      .map(i => {
        return i.nombre;
      })
      .join(",");
  }
}
