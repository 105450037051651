import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";
import {
  ConsumosService,
  EntidadService,
  SuscripcionesService,
  VentasService,
  ComentariosService,
  ContactosService,
  DocumentosService,
  ProductosService,
  UsuariosService
} from "@app/core/services";

// acciones components
import { VentaCrearComponent } from "./entidades/venta/crear/component";
import { VentaCrearFromFileComponent } from "./entidades/venta/crear-from-file/component";
import { EntidadCrearComponent } from "./entidades/entidad/crear/entidad-crear.component";
import { EntidadEditarComponent } from "./entidades/entidad/editar/entidad-editar.component";
import { DocumentoCrearComponent } from "./entidades/documento/crear/documento-crear.component";
import { SuscripcionCrearComponent } from "./entidades/suscripcion/crear/suscripcion-crear.component";
import { ContactoCrearComponent } from "./entidades/contacto/crear/component";
import { ConsumoCrearComponent } from "./entidades/consumo/crear/consumo-crear.component";
import { ComentarioCrearComponent } from "./entidades/comentario/crear/component";
import { ConsumoIngresosCrearComponent } from "./entidades/consumo/crear-ingresos/ingresos-crear.component";

@Component({
  selector: "acciones",
  templateUrl: "./acciones.component.html"
})
export class AccionesComponent implements OnInit {
  @ViewChild("ventaCrearComponent")
  public ventaCrearComponent: VentaCrearComponent;

  @ViewChild("ventaCrearFromFileComponent")
  public ventaCrearFromFileComponent: VentaCrearFromFileComponent;

  @ViewChild("entidadCrearComponent")
  public entidadCrearComponent: EntidadCrearComponent;

  @ViewChild("entidadEditarComponent")
  public entidadEditarComponent: EntidadEditarComponent;

  @ViewChild("documentoCrearComponent")
  public documentoCrearComponent: DocumentoCrearComponent;

  @ViewChild("suscripcionCrearComponent")
  public suscripcionCrearComponent: SuscripcionCrearComponent;

  @ViewChild("consumoCrearComponent")
  public consumoCrearComponent: ConsumoCrearComponent;

  @ViewChild("consumoIngresosCrearComponent")
  public consumoIngresosCrearComponent: ConsumoIngresosCrearComponent;

  @ViewChild("contactoCrearComponent")
  public contactoCrearComponent: ContactoCrearComponent;

  @ViewChild("comentarioCrearComponent")
  public comentarioCrearComponent: ComentarioCrearComponent;

  constructor(
    private router: Router,
    private consumosService: ConsumosService,
    public entidadService: EntidadService,
    public ventasService: VentasService,
    public comentariosService: ComentariosService,
    public contactosService: ContactosService,
    public documentosService: DocumentosService,
    public suscripcionesService: SuscripcionesService,
    public productosService: ProductosService,
    public usuariosService: UsuariosService
  ) { }

  ngOnInit() { }

  public iniciarAccion(accion: AccionInterface) {
    accion.datos = accion.datos || {};
    switch (accion.accion) {
      case EnumAccion.CONSUMO_CREAR:
        if (!accion.datos.consumo) {
          this.consumoCrearComponent.iniciarAccion(accion);
        } else {
          accion.datos.consumo.fecha = accion.datos.consumo.fecha || new Date();
          this.consumosService.create(accion.datos).subscribe(_ => { });
        }
        break;
      case EnumAccion.CONSUMO_INGRESOS_CREAR:
        this.consumoIngresosCrearComponent.iniciarAccion(accion);
        break;
      case EnumAccion.CONSUMO_ELIMINAR:
        this.consumosService
          .update(accion.datos.id, { estado: 0 })
          .subscribe(_ => { });
        break;
      case EnumAccion.CONTACTO_CREAR:
        this.contactoCrearComponent.iniciarAccion(accion);
        break;
      case EnumAccion.CONTACTO_ELIMINAR:
        this.contactosService.delete(accion.datos.id).subscribe(_ => { });
        break;
      case EnumAccion.COMENTARIO_CREAR:
        this.comentarioCrearComponent.iniciarAccion(accion);
        break;
      case EnumAccion.COMENTARIO_ELIMINAR:
        this.comentariosService.delete(accion.datos.id).subscribe(_ => { });
        break;
      case EnumAccion.DOCUMENTO_CREAR:
        this.documentoCrearComponent.iniciarAccion(accion);
        break;
      case EnumAccion.DOCUMENTO_ELIMINAR:
        this.documentosService.delete(accion.datos.id).subscribe(_ => { });
        break;
      case EnumAccion.ENTIDAD_CREAR:
        this.entidadCrearComponent.iniciarAccion(accion);
        break;
      case EnumAccion.ENTIDAD_EDITAR:
        this.entidadEditarComponent.iniciarAccion(accion);
        break;
      case EnumAccion.ENTIDAD_DETALLE:
        this.router.navigate([`/clientes/${accion.datos.id}`]);
        break;
      case EnumAccion.ENTIDAD_EDITAR_CATEGORIA:
        this.entidadService
          .update(accion.datos.id, accion.datos)
          .subscribe(_ => { });
        break;
      case EnumAccion.VENTA_CREAR:
        this.ventaCrearComponent.iniciarAccion(accion);
        break;
      case EnumAccion.VENTA_CREAR_FROM_FILE:
        this.ventaCrearFromFileComponent.iniciarAccion(accion);
        break;
      case EnumAccion.VENTA_CANCELAR:
        this.ventasService
          .update(accion.datos.id, { estado: 0 })
          .subscribe(_ => { });
        break;
      case EnumAccion.VENTA_DETALLE:
        this.router.navigate([`/ventas/${accion.datos.id}`]);
        break;
      case EnumAccion.SUSCRIPCION_CREAR:
        this.suscripcionCrearComponent.iniciarAccion(accion);
        break;
      case EnumAccion.SUSCRIPCION_CANCELAR:
        this.suscripcionesService
          .cancel(accion.datos.id, { fechaFin: accion.datos.fechaFin || new Date() })
          .subscribe(_ => { });
        break;
      case EnumAccion.SUSCRIPCION_ELIMINAR:
        this.suscripcionesService
          .delete(accion.datos.id)
          .subscribe(_ => { });
        break;
      case EnumAccion.SUSCRIPCION_DETALLE:
        this.router.navigate([`/suscripciones/${accion.datos.id}`]);
        break;
      case EnumAccion.PRODUCTO_BLOQUEAR:
        this.productosService.update(accion.datos.id, { estado: 0 }).subscribe(_ => { });
        break;
      case EnumAccion.PRODUCTO_DESBLOQUEAR:
        this.productosService.update(accion.datos.id, { estado: 1 }).subscribe(_ => { });
        break;
      case EnumAccion.USUARIO_BLOQUEAR:
        this.usuariosService.update(accion.datos.id, { estado: 0 }).subscribe(_ => { });
        break;
      case EnumAccion.USUARIO_DESBLOQUEAR:
        this.usuariosService.update(accion.datos.id, { estado: 1 }).subscribe(_ => { });
        break;
      case EnumAccion.REPORTES_VENTAS:
        this.router.navigate([`/reportes/ventas`]);
        break;
      default:
        break;
    }
  }
}
