import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../../core/services";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styles: []
})
export class AuthLayoutComponent implements OnInit {
  constructor(public appService: AppService) {}
  ngOnInit() {}
}
