import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'estadoUsuario' })
export class EstadoUsuarioPipe implements PipeTransform {
    transform(estado: number): String {
        switch (estado) {
            case 0:
                return `<span class="badge badge-warning badge-estado">
                            <span class="texto">BLOQUEADO</span>
                        </span>`;
            case 1:
                return `<span class="badge badge-success badge-estado">
                            <span class="texto">ACTIVO</span>
                        </span>`;
            default:
                return ``;
        }
    }
}