import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SuscripcionesService } from "@app/core/services";
import { EnumFormaDePago } from "@app/core/enums";
import { BsLocaleService } from "ngx-bootstrap";
import { DatePipe } from "@angular/common";

@Component({
  selector: "suscripcion-crear",
  templateUrl: "./suscripcion-crear.component.html"
})
export class SuscripcionCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public entidadId;

  public valuesFormasDePago = EnumFormaDePago.values();

  constructor(
    private entidadSuscripcionService: SuscripcionesService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    public bsLocaleService: BsLocaleService,
    private datePipe: DatePipe
  ) {
    this.bsLocaleService.use("es");
  }

  ngOnInit() {}

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
    this.entidadId = null;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();
    this.entidadId = accion.datos.entidad;
    this.form = this.formBuilder.group({
      producto: ["", Validators.required],
      monto: [null],
      formaDePago: [EnumFormaDePago.OTRA, Validators.required],
      fechaInicio: [new Date(), Validators.required],
      fechaFin: [],
      ventaAutomatica: [true, Validators.required]
    });

    this.cargando = false;
    this.modal = this.modalService.show(this.template);
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;
    console.log(this.f);

    if (this.form.invalid) {
      return;
    }

    let suscripcion = {
      entidad: this.entidadId,
      producto: this.f.producto.value,
      formaDePago: this.f.formaDePago.value,
      fechaInicio: this.datePipe.transform(this.f.fechaInicio.value, "yyyy-MM-dd 00:00"),
      ventaAutomatica: this.f.ventaAutomatica.value
    };

    if (this.f.fechaFin.value !=null) suscripcion['fechaFin'] = this.datePipe.transform(this.f.fechaFin.value, "yyyy-MM-dd 00:00");
    if (this.f.monto.value !=null) suscripcion['monto'] = this.f.monto.value;

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};

    this.entidadSuscripcionService.create(suscripcion).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
