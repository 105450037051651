import { Component } from "@angular/core";
import { ISuscripcion } from "@app/core/interfaces";
import { SuscripcionesService, AppService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { LayoutService } from "@app/shared/layout/layout.service";
import { dateToFechaHasta } from "@app/utils";

@Component({
  selector: "suscripciones-listado",
  templateUrl: "suscripciones-listado.component.html",
  providers: [LayoutService]
})
export class SuscripcionesListado extends ListadoBaseComponent<ISuscripcion> {
  public maxDate = dateToFechaHasta(new Date());

  public filtrosVisibilidad = [
    'estado'
  ]

  public filtroEstados = [
    {
      nombre: "Terminadas",
      valor: 0
    },
    {
      nombre: "Activas",
      valor: 1
    },
    {
      nombre: "Eliminadas",
      valor: 99
    }
  ]

  constructor(protected service: SuscripcionesService, public appService:AppService) {
    super(service);
  }

  public hasPermision(permision: string): boolean {
    return this.appService.hasPermision(permision);
  }
}
