import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  ElementRef,
  OnInit,
  DoCheck
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

declare var $: any;

@Component({
  selector: "select-enumerado",
  templateUrl: "./component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectEnumeradoComponent),
      multi: true
    }
  ]
})
export class SelectEnumeradoComponent
  implements ControlValueAccessor, OnInit, DoCheck {
  @ViewChild("select")
  private select: ElementRef;
  private select2: any;
  propagateChange = (_: any) => {};

  @Input()
  data: any[];

  @Input()
  placeholder: any = "";

  constructor() {}

  ngOnInit() {
    let placeholder = this.placeholder;
    let data = this.data;

    this.select2 = $(this.select.nativeElement).select2({
      placeholder: placeholder,
      data: data
    });
  }

  @Input()
  _value: any = "";
  get value() {
    this._value = this.select2.val();
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }

  ngDoCheck() {
    if (this._value != this.select2.val()) this.value = this.select2.val();
  }

  writeValue(value: any) {
    this._value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
