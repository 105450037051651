export enum EnumAccion {
  ASIGNACION_DETALLE = "ASIGNACION_DETALLE",
  ASIGNACION_CREAR = "ASIGNACION_CREAR",
  ASIGNACION_CANCELAR = "ASIGNACION_CANCELAR",
  ASIGNACION_EDITAR = "ASIGNACION_EDITAR",
  CONSUMIBLE_CREAR = "CONSUMIBLE_CREAR",
  CONSUMIBLE_BLOQUEAR = "CONSUMIBLE_BLOQUEAR",
  CONSUMIBLE_EDITAR = "CONSUMIBLE_EDITAR",
  CONSUMO_CREAR = "CONSUMO_CREAR",
  CONSUMO_INGRESOS_CREAR = "CONSUMO_INGRESOS_CREAR",
  CONSUMO_ELIMINAR = "CONSUMO_ELIMINAR",
  CONSUMO_EDITAR = "CONSUMO_EDITAR",
  CONTACTO_CREAR = "CONTACTO_CREAR",
  CONTACTO_ELIMINAR = "CONTACTO_ELIMINAR",
  CONTACTO_EDITAR = "CONTACTO_EDITAR",
  COMENTARIO_CREAR = "COMENTARIO_CREAR",
  COMENTARIO_ELIMINAR = "COMENTARIO_ELIMINAR",
  COMENTARIO_EDITAR = "COMENTARIO_EDITAR",
  DOCUMENTO_CREAR = "DOCUMENTO_CREAR",
  DOCUMENTO_ELIMINAR = "DOCUMENTO_ELIMINAR",
  DOCUMENTO_EDITAR = "DOCUMENTO_EDITAR",
  DESCUENTO_PRODUCTO_CREAR = "DESCUENTO_PRODUCTO_CREAR",
  DESCUENTO_PRODUCTO_BLOQUEAR = "DESCUENTO_PRODUCTO_BLOQUEAR",
  DESCUENTO_PRODUCTO_EDITAR = "DESCUENTO_PRODUCTO_EDITAR",
  ENTIDAD_DETALLE = "ENTIDAD_DETALLE",
  ENTIDAD_CREAR = "ENTIDAD_CREAR",
  ENTIDAD_ELIMINAR = "ENTIDAD_ELIMINAR",
  ENTIDAD_EDITAR = "ENTIDAD_EDITAR",
  ENTIDAD_EDITAR_CATEGORIA = "ENTIDAD_EDITAR_CATEGORIA",
  PRODUCTO_CREAR = "PRODUCTO_CREAR",
  PRODUCTO_BLOQUEAR = "PRODUCTO_BLOQUEAR",
  PRODUCTO_DESBLOQUEAR = "PRODUCTO_DESBLOQUEAR",
  PRODUCTO_EDITAR = "PRODUCTO_EDITAR",
  USUARIO_CREAR = "USUARIO_CREAR",
  USUARIO_BLOQUEAR = "USUARIO_BLOQUEAR",
  USUARIO_DESBLOQUEAR = "USUARIO_DESBLOQUEAR",
  USUARIO_EDITAR = "USUARIO_EDITAR",
  VENTA_DETALLE = "VENTA_DETALLE",
  VENTA_CREAR = "VENTA_CREAR",
  VENTA_CREAR_FROM_FILE = "VENTA_CREAR_FROM_FILE",
  VENTA_CANCELAR = "VENTA_CANCELAR",
  VENTA_EDITAR = "VENTA_EDITAR",
  SUSCRIPCION_DETALLE = "SUSCRIPCION_DETALLE",
  SUSCRIPCION_CREAR = "SUSCRIPCION_CREAR",
  SUSCRIPCION_CANCELAR = "SUSCRIPCION_CANCELAR",
  SUSCRIPCION_ELIMINAR = "SUSCRIPCION_ELIMINAR",
  SUSCRIPCION_EDITAR = "SUSCRIPCION_EDITAR",
  REPORTES_VENTAS = "REPORTES_VENTAS"
}
