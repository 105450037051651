import { Pipe } from "@angular/core";
import { DiccionarioBasePipe } from "./diccionario.base.pipe";

@Pipe({
  name: "siono",
  pure: false
})
export class SiONoDiccionarioPipe extends DiccionarioBasePipe {
  protected diccionario;

  constructor() {
    super();
    this.diccionario = {
      true: "Sí",
      false: "No"
    };
  }
}
