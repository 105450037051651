export enum EnumTipoDocumento {
  CDSA = 1,
  FMED = 2,
  OTRO = 99
}

export namespace EnumTipoDocumento {
  export function values(): { id: number; nombre: string }[] {
    return [
      { id: 0, nombre: "Carne de Salud" },
      { id: 1, nombre: "Ficha Médica" },
      { id: 99, nombre: "Otro" }
    ];
  }
}
