import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";

@Injectable()
export class VentasService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/ventas";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.ventas,
      this.appService.servicios.clientes,
      this.appService.servicios.ingresos
    ];
  }

  public getAcciones(item: any): AccionInterface[] {
    let acciones: AccionInterface[] = [
      {
        titulo: "Ver Detalle",
        accion: EnumAccion.VENTA_DETALLE,
        icono: "plus",
        datos: { id: item.id }
      }
      /* ,
      {
        titulo: "Editar",
        accion: EnumAccion.VENTA_EDITAR,
        icono: "pencil-alt",
        datos: { id: item.id }
      } */
    ];

    if (item.estado == 1) {
      acciones.push({
        titulo: "Cancelar",
        accion: EnumAccion.VENTA_CANCELAR,
        icono: "ban",
        datos: { id: item.id, estado: 0 }
      });
    }
    return acciones;
  }
}
