import { Component } from "@angular/core";
import { SuscripcionesService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { ISuscripcion } from "@app/core/interfaces";
import { LayoutService } from "@app/shared/layout/layout.service";

@Component({
  selector: "suscripciones-listado-tabla",
  templateUrl: "component.html",
  providers: [LayoutService]
})
export class SuscripcionesListadoTabla extends ListadoBaseComponent<
  ISuscripcion
> {
  constructor(protected service: SuscripcionesService) {
    super(service);
  }
}
