import { NgModule } from "@angular/core";
import { WebAppLayoutModule } from "./layout/layout.module";
import { AccionesModule } from "./acciones/acciones.module";
import { PipesModule } from "./pipes/pipes.module";
import { UtilsModule } from "./utils/utils.module";
import { EntidadesModule } from "./components/entidades.module";

@NgModule({
  exports: [
    UtilsModule,
    AccionesModule,
    WebAppLayoutModule,
    PipesModule,
    EntidadesModule
  ]
})
export class SharedModule {}
