import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AccionInterface } from "../interfaces";

export const ribbonDefault = {
  titulo: "",
  subtitulo: ""
};

@Injectable()
export class RibbonService {
  public ribbon$ = new BehaviorSubject(ribbonDefault);
  public acciones$:BehaviorSubject<AccionInterface[]> = new BehaviorSubject([]);

  constructor() {}

  public setRibbon(ribbon) {
    this.ribbon$.next(ribbon);
  }

  public setAcciones(acciones) {
    this.acciones$.next(acciones);
  }
}
