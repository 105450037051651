import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  ElementRef,
  OnInit
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { environment } from "@env/environment";
import { EnumEntidad } from "@app/core/enums";

declare var $: any;

@Component({
  selector: "select-entidad",
  templateUrl: "./select-entidad.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectEntidadComponent),
      multi: true
    }
  ]
})
export class SelectEntidadComponent implements ControlValueAccessor, OnInit {
  @ViewChild("select")
  select: ElementRef;

  @Input()
  entidad: any = "";

  @Input()
  filtros: any = {};

  @Input()
  placeholder: any = "";

  @Input()
  allowClear: boolean = false;

  @Input()
  public _value: any = "";

  private _select2: any;
  propagateChange = (_: any) => {};

  private usuario;

  constructor() {
    this.usuario = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  ngOnInit() {
    let placeholder = this.placeholder;
    let url = "";
    switch (this.entidad) {
      case EnumEntidad.CONSUMIBLE:
        placeholder = placeholder || "Seleccione un producto";
        url = environment.base_url + "/admin/consumibles/buscador";
        break;
      case EnumEntidad.ENTIDAD:
        placeholder = placeholder || "Seleccione un cliente";
        url = environment.base_url + "/admin/entidades/buscador";
        break;
      case EnumEntidad.PRODUCTO:
        placeholder = placeholder || "Seleccione un producto";
        url = environment.base_url + "/admin/productos/buscador";
        break;
      case EnumEntidad.CATEGORIA_ENTIDAD:
        placeholder = placeholder || "Seleccione una categoria";
        url = environment.base_url + "/admin/entidades/categorias/buscador";
        break;
      case EnumEntidad.USUARIO:
        placeholder = placeholder || "Seleccione un usuario";
        url = environment.base_url + "/admin/usuarios/buscador";
        break;
      case "FORMA_DE_PAGO":
        placeholder = placeholder || "Seleccione una forma de pago";
        url = environment.base_url + "/admin/enumerados/formas-de-pago";
        break;
      default:
        break;
    }

    let query: string = Object.getOwnPropertyNames(this.filtros)
      .reduce((res: string[], key: string) => {
        res.push(`${key}=${this.filtros[key]}`);
        return res;
      }, [])
      .join("&");
    url = `${url}?${query}`;

    let that = this;
    this._select2 = $(this.select.nativeElement)
      .select2({
        placeholder,
        allowClear: true,
        ajax: {
          url: url,
          cache: true,
          data: function(params) {
            var query = {
              texto: params.term
            };
            return query;
          },
          processResults: function(data, params) {
            return {
              results: data
            };
          },
          delay: 250,
          headers: {
            Authorization: this.usuario.token,
            "Content-Type": "application/json"
          },
          dataType: "json"
        }
      })
      .on("change", function(e) {
        that.value = that._select2.val();
      });

      that._select2.val(that._value);
  }

  get value() {
    this._value = this._select2.val();
    console.log(this._value );
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.propagateChange(this._value);
  }

  writeValue(value: any) {    
    let that = this;
    setTimeout(function() {      
      that._value = value;
      that._select2.val(value);
      that._select2.trigger("change");
    }, 100);       
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
