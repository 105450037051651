import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'asignacionConsumo' })
export class AsignacionConsumoPipe implements PipeTransform {
    transform(estado: number): String {
        switch (estado) {
            case null:
                return `<span class="badge badge-danger badge-estado">
                            <span class="texto">PENDIENTE</span>
                        </span>`;
            default:
                return `<span class="badge badge-success badge-estado">
                            <span class="texto">ASIGNADO</span>
                        </span>`;
        }
    }
}