import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { registerLocaleData } from "@angular/common";
import { defineLocale } from "ngx-bootstrap/chronos";
import { esLocale } from "ngx-bootstrap/locale";

interface WebpackRequire {
  (id: string): any;
  (paths: string[], callback: (...modules: any[]) => void): void;
  ensure(
    ids: string[],
    callback: (req: WebpackRequire) => void,
    chunkName?: string
  ): void;
  context(
    directory: string,
    useSubDirectories?: boolean,
    regExp?: RegExp
  ): WebpackContext;
}

interface WebpackContext extends WebpackRequire {
  keys(): string[];
}

interface NodeRequire extends WebpackRequire { }
declare var require: NodeRequire;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

const locale = require(`@angular/common/locales/es`).default;
registerLocaleData(locale, "es");
defineLocale("es", esLocale);