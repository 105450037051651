import { Component } from "@angular/core";
import { ProductosService, AppService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { ProductoInterface } from "@app/core/interfaces";

@Component({
  selector: "productos-listado",
  templateUrl: "productos-listado.component.html"
})
export class ProductosListadoComponent extends ListadoBaseComponent<ProductoInterface> {
  public filtroEstados = [
    {
      nombre: "No Activos",
      valor: 0
    },
    {
      nombre: "Activos",
      valor: 1
    }
  ]

  constructor(protected service: ProductosService, public appService:AppService) {
    super(service);
  }

  public hasPermision(permision:string):boolean{
    return this.appService.hasPermision(permision);
  }
}
