import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  public get(url, options?): Observable<any> {
    return this.http
      .get(environment.base_url + url, options)
      .pipe(catchError(this.handleError));
  }

  public post(url, datos): Observable<any> {
    return this.http
      .post(environment.base_url + url, datos)
      .pipe(catchError(this.handleError));
  }

  public put(url, datos): Observable<any> {
    return this.http
      .put(environment.base_url + url, datos)
      .pipe(catchError(this.handleError));
  }

  public delete(url): Observable<any> {
    return this.http
      .delete(environment.base_url + url)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    return throwError(error);
  }
}
