import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>"
})
export class AppComponent {  
  title = environment.instancia.nombreInstancia + " | Administrador";
  constructor(private titleService:Title) {
    this.titleService.setTitle(this.title);
  }
}
