import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConsumosService } from "@app/core/services";
import { EnumFormaDePago } from "@app/core/enums";
import { DatePipe } from "@angular/common";
import { BsLocaleService } from "ngx-bootstrap";

@Component({
  selector: "consumo-crear",
  templateUrl: "./consumo-crear.component.html"
})
export class ConsumoCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public entidadId;

  public valuesFormasDePago = EnumFormaDePago.values();

  constructor(
    private consumosService: ConsumosService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public bsLocaleService: BsLocaleService
  ) {
    this.bsLocaleService.use("es");
  }

  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
    this.entidadId = null;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    let d = new Date();
    let hora = `${d.getHours().toString()}:${d.getMinutes().toString()}`;

    this.limpiarFormulario();
    this.entidadId = accion.datos.entidad;
    this.form = this.formBuilder.group({
      consumible: [accion.datos.consumible || "", Validators.required],
      fecha: [this.datePipe.transform(d, "yyyy-MM-dd"), Validators.required],
      hora: [hora, Validators.required]
    });

    this.cargando = false;
    this.modal = this.modalService.show(this.template);
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    let consumo = {
      entidad: this.entidadId,
      cantidad: 1,
      consumible: this.f.consumible.value,
      fecha: new Date(`${this.datePipe.transform(this.f.fecha.value, "yyyy-MM-dd")} ${this.f.hora.value}`)
    };

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};

    this.consumosService.create({ consumos: [consumo] }).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
