import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";

@Injectable()
export class EntidadService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/entidades";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.clientes
    ];
  }

  public getAcciones(item: any): AccionInterface[] {
    return [
      {
        titulo: "Registrar Venta",
        accion: EnumAccion.VENTA_CREAR,
        icono: "cart-plus",
        datos: { entidad: item.id }
      },
      {
        titulo: "Registrar Suscripción",
        accion: EnumAccion.SUSCRIPCION_CREAR,
        icono: "credit-card",
        datos: { entidad: item.id }
      },
      {
        titulo: "Ingresar Ahora",
        accion: EnumAccion.CONSUMO_CREAR,
        icono: "binoculars",
        datos: { consumo: { entidad: item.id, consumible: 1, cantidad: 1 } }
      }
    ];
  }
}
