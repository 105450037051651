import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EntidadService } from "@app/core/services";
import { Router } from "@angular/router";
import { IEntidad } from "@app/core/interfaces";
import { BsLocaleService } from "ngx-bootstrap";
import { dateToFechaHasta } from "@app/utils";
import { DatePipe } from "@angular/common";

@Component({
  selector: "entidad-editar",
  templateUrl: "./entidad-editar.component.html"
})
export class EntidadEditarComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public entidad: IEntidad;

  public maxDate = dateToFechaHasta(new Date());

  constructor(
    private entidadService: EntidadService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
    public bsLocaleService: BsLocaleService,
    private datePipe: DatePipe
  ) {
    this.bsLocaleService.use("es");
  }
  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.cargando = true;
    this.limpiarFormulario();
    this.entidadService.getById(accion.datos.entidad).subscribe(entidad => {
      this.entidad = entidad;

      this.form = this.formBuilder.group({
        nombre: [entidad.nombre, Validators.required],
        apellido: [entidad.apellido, Validators.required],
        documentoNumero: [entidad.documentoNumero, Validators.required],
        ingresoFecha: [entidad.ingresoFecha? this.datePipe.transform(entidad.ingresoFecha, "yyyy-MM-dd") : null],
        nacimientoFecha: [entidad.nacimientoFecha? this.datePipe.transform(entidad.nacimientoFecha, "yyyy-MM-dd"): null],
        emergenciaMedica: entidad.emergenciaMedica || null,
        atencionMedica: entidad.atencionMedica || null,
        referencia: [entidad.referencia || null],
        esSinRutina: [entidad.esSinRutina || false, Validators.required],
        esPrincipiante: [entidad.esPrincipiante || false, Validators.required]
      });

      this.cargando = false;
      this.modal = this.modalService.show(this.template);
    });
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;
    console.log(this.f);

    if (this.form.invalid) {
      return;
    }

    this.cargando = true;
    this.mensaje = `Enviando Datos`;
    this.error = {};

    let entidad: any = {};

    if (this.f.nombre.value) entidad.nombre = this.f.nombre.value;
    if (this.f.apellido.value) entidad.apellido = this.f.apellido.value;
    if (this.f.nacimientoFecha.value) entidad.nacimientoFecha = this.datePipe.transform(this.f.nacimientoFecha.value, "yyyy-MM-dd 00:00");
    if (this.f.documentoNumero.value) {
      entidad.documentoTipo = 0;
      entidad.documentoNumero = this.f.documentoNumero.value;
    }
    if (this.f.referencia.value) entidad.referencia = this.f.referencia.value;
    if (this.f.esPrincipiante.value) entidad.esPrincipiante = this.f.esPrincipiante.value;
    if (this.f.ingresoFecha.value) entidad.ingresoFecha = this.datePipe.transform(this.f.ingresoFecha.value, "yyyy-MM-dd 00:00");
    if (this.f.emergenciaMedica.value) entidad.emergenciaMedica = this.f.emergenciaMedica.value;
    if (this.f.atencionMedica.value) entidad.atencionMedica = this.f.atencionMedica.value;
    if (this.f.esSinRutina.value) entidad.esSinRutina = this.f.esSinRutina.value;

    entidad.entidad = this.entidad.id;

    this.entidadService.update(this.entidad.id, entidad).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
