import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "../app/shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "../app/shared/layout/app-layouts/auth-layout.component";

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    children: [
     /*  {
        path: "",
        loadChildren: "./features/inicio/module#InicioModule",
        data: { pageTitle: "Recepción", pageIcon:"home", modulo: "inicio" }
      },   */
      {
        path: "",
        loadChildren: "./features/recepcion/module#RecepcionModule",
        data: { pageTitle: "Recepción", pageIcon:"home", modulo: "inicio" }
      },      
      {
        path: "ingresos",
        loadChildren: "./features/ingresos/module#IngresosModule",
        data: { pageTitle: "Ingresos", pageIcon:"binoculars", modulo: "ingresos" }
      },
      {
        path: "clientes",
        loadChildren: "./features/clientes/module#ClientesModule",
        data: { pageTitle: "Clientes", pageIcon:"male", modulo: "clientes" }
      },      
      {
        path: "ventas",
        loadChildren: "./features/ventas/module#VentasModule",
        data: { pageTitle: "Ventas", pageIcon:"store", modulo: "ventas" }
      },
      {
        path: "suscripciones",
        loadChildren: "./features/suscripciones/module#SuscripcionesModule",
        data: { pageTitle: "Suscripciones", pageIcon:"credit-card", modulo: "suscripciones" }
      },
      {
        path: "productos",
        loadChildren: "./features/productos/module#ProductosModule",
        data: { pageTitle: "Productos", pageIcon:"box", modulo: "productos" }
      },
      {
        path: "reportes",
        loadChildren: "./features/reportes/module#ReportesModule",
        data: { pageTitle: "Reportes", pageIcon:"chart-line", modulo: "reportes" }
      },
      {
        path: "usuarios",
        loadChildren: "./features/usuarios/module#UsuariosModule",
        data: { pageTitle: "Usuarios", pageIcon:"users", modulo: "usuarios" }
      }
    ]
  },
  {
    path: "login",
    component: AuthLayoutComponent,
    loadChildren: "./features/login/module#LoginModule"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
