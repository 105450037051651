import { Pipe } from "@angular/core";
import { DiccionarioBasePipe } from "./diccionario.base.pipe";

@Pipe({
  name: "formaDePago",
  pure: false
})
export class FormaDePagoDiccionarioPipe extends DiccionarioBasePipe {
  protected diccionario;

  constructor() {
    super();
    this.diccionario = {
      0: "Efectivo",
      1: "Crédito",
      2: "Débito",
      3: "Cripto",
      4: "Mercado Pago",
      5: "Debito Automático",
      99: "Otra"
    };
  }
}
