import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  VentasService,
  ProductosService,
  EntidadService
} from "@app/core/services";
import { EnumTipoDescuento, EnumFormaDePago } from "@app/core/enums";
import { IEntidad } from "@app/core/interfaces";

@Component({
  selector: "venta-crear",
  templateUrl: "./component.html"
})
export class VentaCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public entidad: IEntidad;
  public producto: any;

  public valuesFormasDePago = EnumFormaDePago.values();

  constructor(
    private ventasService: VentasService,
    private productosService: ProductosService,
    private entidadService: EntidadService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) { }

  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
    this.entidad = null;
    this.producto = null;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();

    this.cargando = true;
    this.entidadService.getById(accion.datos.entidad).subscribe(entidad => {
      this.entidad = entidad;
      var date = new Date();
      var ultimoDia = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
        23,
        59
      );

      let month = date.getMonth().toString();
      month = date.getMonth() < 10 ? "0" + month : month;

      this.form = this.formBuilder.group({
        producto: [null, Validators.required],
        descuento: [0],
        productoPrecio: [0, Validators.required],
        validoDesdeDia: ["01", Validators.required],
        validoDesdeMes: [month, Validators.required],
        validoDesdeAnio: [date.getFullYear(), Validators.required],
        validoHastaDia: [ultimoDia.getDate(), Validators.required],
        validoHastaMes: [month, Validators.required],
        validoHastaAnio: [ultimoDia.getFullYear(), Validators.required],
        vigenciaMes: [date.getMonth() + 1, Validators.required],
        vigenciaAnio: [date.getFullYear(), Validators.required],
        formaDePago: [0, Validators.required],
        enviarConfirmacion: [true, Validators.required]
      });

      this.cargando = false;
      this.modal = this.modalService.show(this.template);
      this.onChanges();
    });
  }

  protected onValidoFecha(mes: number, anio: number) {
    var date = new Date(anio, mes, 1);
    var ultimoDia = new Date(
      date.getFullYear(),
      date.getMonth(),
      0,
      23,
      59,
      59
    );

    let month = date.getMonth().toString();
    month = date.getMonth() < 10 ? "0" + month : month;

    this.f.validoDesdeDia.setValue("01");
    this.f.validoDesdeMes.setValue(month);
    this.f.validoDesdeAnio.setValue(anio);

    this.f.validoHastaDia.setValue(ultimoDia.getDate());
    this.f.validoHastaMes.setValue(month);
    this.f.validoHastaAnio.setValue(anio);
  }

  onChanges(): void {
    this.form.get("producto").valueChanges.subscribe(val => {
      if (val != null) {
        this.productosService.getById(val).subscribe(producto => {
          this.producto = producto;
          this.f.productoPrecio.setValue(producto.precio);

          let descuentos = this.producto.descuentos.filter(descuento => {
            return this.entidad.descuentos.find(d => {
              return d.id == descuento.id;
            });
          });

          this.f.descuento.setValue(
            descuentos.length > 0 ? descuentos[0].id : 0
          );
        });
      } else {
        this.producto = null;
      }
    });

    this.form.get("descuento").valueChanges.subscribe(val => {
      if (this.producto) {
        let descuentos = [parseInt(val)];
        let precio = this.producto.descuentos.reduce((precio, descuento) => {
          if (descuentos.indexOf(descuento.id) > -1) {
            if (descuento.tipo == EnumTipoDescuento.PORCENTAJE)
              precio = precio - (precio * descuento.valor) / 100;
            else if (descuento.tipo == EnumTipoDescuento.MONTO)
              precio = precio - descuento.valor;
          }
          return precio;
        }, this.producto.precio);
        this.f.productoPrecio.setValue(precio);
      } else {
        this.f.productoPrecio.setValue(0);
      }
    });

    this.form.get("vigenciaMes").valueChanges.subscribe(val => {
      if (val) {
        this.onValidoFecha(this.f.vigenciaMes.value, this.f.vigenciaAnio.value);
      }
    });

    this.form.get("vigenciaAnio").valueChanges.subscribe(val => {
      if (val) {
        this.onValidoFecha(this.f.vigenciaMes.value, this.f.vigenciaAnio.value);
      }
    });
  }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;
    console.log(this.f);

    if (this.form.invalid) {
      return;
    }

    let items: any[] = [
      {
        producto: this.f.producto.value,
        cantidad: 1,
        validoDesde: new Date(`${this.f.validoDesdeAnio.value}-${ this.f.validoDesdeMes.value}-${this.f.validoDesdeDia.value} 00:00:00`),
        validoHasta: new Date(`${this.f.validoHastaAnio.value}-${ this.f.validoHastaMes.value }-${this.f.validoHastaDia.value} 23:59:59`)
      }
    ];

    if (this.f.descuento.value != 0) {
      items.push({
        descuento: this.f.descuento.value,
        cantidad: 1
      });
    } else {
      items[0].monto = this.f.productoPrecio.value;
    }

    let venta = {
      entidad: this.entidad.id,
      items: items,
      pagos: [
        {
          monto: this.f.productoPrecio.value,
          formaDePago: this.f.formaDePago.value
        }
      ],
      enviarConfirmacion: this.f.enviarConfirmacion.value
    };

    this.cargando = true;
    this.mensaje = `Enviando Datos`;

    this.error = {};

    this.ventasService.create(venta).subscribe(
      (resultado: any) => {
        this.cargando = false;
        this.modal.hide();
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
