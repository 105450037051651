import { Injectable } from "@angular/core";
import * as socketIo from 'socket.io-client';
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { EventoInterface } from "../interfaces";

@Injectable()
export class SocketService {
  private socket;
  
  constructor() {
    this.socket = socketIo(environment.base_url);
  }

  public onEvento(): Observable<EventoInterface> {
    return new Observable<EventoInterface>(observer => {
      this.socket.on('evento', (data: EventoInterface) => observer.next(data));
    });
  }
}
