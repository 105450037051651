import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppService } from "../services";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private appService: AppService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        map((res: any) => {
          if (res.body && res.body.notificaciones) {
            this.appService.notificaciones$.next(res.body.notificaciones);
            delete res.body.notificaciones;
          }
          return res;
        })
      )
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            this.appService.logout();
          }
          const error = err.error || err.statusText;
          if (err.notificaciones) {
            this.appService.notificaciones$.next(err.notificaciones);
            delete err.notificaciones;
          }
          return throwError(error);
        })
      );
  }
}
