import { PipeTransform } from "@angular/core";
export class DiccionarioBasePipe implements PipeTransform {
  protected diccionario: any;

  constructor() {}

  transform(item: number): string {
    return this.diccionario[item] || "Desconocido";
  }
}
