import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";

@Injectable()
export class AsignacionesService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/asignaciones";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.clientes
    ];
  }

  public getAcciones(item: any): AccionInterface[] {
    let acciones: AccionInterface[] = [
      /* {
        titulo: "Ver detalle",
        accion: EnumAccion.ASIGNACION_DETALLE,
        icono: "plus",
        datos: { id: item.id }
      } */
    ];

    if (item.ventaItem != null) {
      acciones.concat([
        {
          titulo: "Cancelar",
          accion: EnumAccion.ASIGNACION_CANCELAR,
          icono: "ban",
          datos: { id: item.id, estado: 0 }
        } /* ,
        {
          titulo: "Editar",
          accion: EnumAccion.ASIGNACION_EDITAR,
          icono: "pencil-alt",
          datos: { id: item.id }
        } */
      ]);
    }

    return acciones;
  }
}
