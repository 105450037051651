import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { ApiService } from "./../api.service";
import { FiltroInterface, IModulo, AccionInterface } from "../../interfaces";
import { map, catchError } from "rxjs/operators";
import { AppService } from "@app/core/services/app.service";
import { EnumTipoNotificacion } from "@app/core/enums";

@Injectable()
export class BaseService {
  protected entityUrl: string;
  protected servicios: BehaviorSubject<IModulo>[] = [];

  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) { }

  public get(filtros: FiltroInterface = {}): Observable<any> {
    let query: string = Object.getOwnPropertyNames(filtros)
      .reduce((res: string[], key: string) => {
        res.push(`${key}=${filtros[key]}`);
        return res;
      }, [])
      .join("&");
    return this.apiService.get(`${this.entityUrl}?${query}`).pipe(
      map(res => {
        res.listado = res.listado.map(item => {
          item._acciones = this.getAcciones(item);
          return item;
        });
        return res;
      })
    );
  }

  public buscador(filtros: FiltroInterface = {}): Observable<any> {
    let query: string = Object.getOwnPropertyNames(filtros)
      .reduce((res: string[], key: string) => {
        res.push(`${key}=${filtros[key]}`);
        return res;
      }, [])
      .join("&");
    return this.apiService.get(`${this.entityUrl}/buscador?${query}`);
  }

  public descargar(filtros:FiltroInterface = {}): Observable<any> {
    let query: string = Object.getOwnPropertyNames(filtros)
      .reduce((res: string[], key: string) => {
        res.push(`${key}=${filtros[key]}`);
        return res;
      }, [])
      .join("&");
    return this.apiService.get(`${this.entityUrl}/descargar?${query}`, { responseType: "text/csv" }).pipe(map(res => {
      let options = {
        type: 'text/csv;charset=utf-8;'
      };
      let filename = 'myfile.csv';
      this.createAndDownloadBlobFile(res, options, filename);
      return;
    })).pipe(catchError(error => {
      return error;
    }));
  }

  public getById(id: number): Observable<any> {
    return this.apiService.get(`${this.entityUrl}/${id}`);
  }

  public create(datos: any): Observable<any> {
    return this.apiService.post(`${this.entityUrl}`, datos).pipe(
      map(
        res => {
          this.servicios.forEach((servicio: BehaviorSubject<IModulo>) => {
            let servicioNuevo = servicio.value;
            servicioNuevo.ultimaActualizacion = new Date();
            servicio.next(servicioNuevo);
          });
          return res;
        }
      )
    );
  }

  public update(id: number, datos: any, mensaje?: string): Observable<any> {
    return this.apiService.put(`${this.entityUrl}/${id}`, datos).pipe(
      map(
        res => {
          this.servicios.forEach((servicio: BehaviorSubject<IModulo>) => {
            let servicioNuevo = servicio.value;
            servicioNuevo.ultimaActualizacion = new Date();
            servicio.next(servicioNuevo);
          });
          return res;
        }
      )
    );
  }

  public cancel(id: number, datos: any): Observable<any> {
    return this.apiService.put(`${this.entityUrl}/${id}/cancel`, datos).pipe(
      map(
        res => {
          this.servicios.forEach((servicio: BehaviorSubject<IModulo>) => {
            let servicioNuevo = servicio.value;
            servicioNuevo.ultimaActualizacion = new Date();
            servicio.next(servicioNuevo);
          });
          return res;
        }
      )
    );
  }

  public delete(id: number): Observable<any> {
    return this.apiService.delete(`${this.entityUrl}/${id}`).pipe(
      map(res => {
        this.servicios.forEach((servicio: BehaviorSubject<IModulo>) => {
          let servicioNuevo = servicio.value;
          servicioNuevo.ultimaActualizacion = new Date();
          servicio.next(servicioNuevo);
        });
        return res;
      })
    );
  }  

  public getAcciones(item: any): AccionInterface[] {
    return [];
  }

  private createAndDownloadBlobFile(body, options, filename) {
    var blob = new Blob([body], options);
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
