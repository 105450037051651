import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EntidadService } from "@app/core/services";
import { Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap";
import { dateToFechaHasta } from "@app/utils";
import { DatePipe } from "@angular/common";

@Component({
  selector: "entidad-crear",
  templateUrl: "./entidad-crear.component.html"
})
export class EntidadCrearComponent implements OnInit {
  private modal: BsModalRef;

  @ViewChild("template")
  private template: ElementRef;

  public cargando = false;
  public mensaje = "";
  public error: any = {};

  public form: FormGroup;
  public formSubmitted = false;

  public maxDate = dateToFechaHasta(new Date());

  constructor(
    private entidadService: EntidadService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
    public bsLocaleService: BsLocaleService,
    private datePipe: DatePipe
  ) {
    this.bsLocaleService.use("es");
  }

  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  public getErrors(campo: string, key: string) {
    if (!this.formSubmitted || !this.form) return false;
    else {
      let errores = [];
      if (this.f && this.f[key] && this.f[key].errors) {
        if (this.f && this.f[key] && this.f[key].errors.required) {
          errores.push(`${campo} es requerido.`);
        }
      }
      if (this.error.errors && this.error.errors[key]) {
        errores.push(this.error.errors[key]);
      }
      return errores.join(" ");
    }
  }

  //LIMPIAR FORMULARIO
  limpiarFormulario() {
    this.cargando = true;
    this.mensaje = "";
    this.error = {};
    this.formSubmitted = false;
  }

  //INICIAR ACCION
  async iniciarAccion(accion: any) {
    this.limpiarFormulario();
    var date = new Date();
    this.form = this.formBuilder.group({
      nombre: ["", Validators.required],
      apellido: ["", Validators.required],
      email: [""],
      celular: [""],
      ingresoFecha: [this.datePipe.transform(date, "yyyy-MM-dd"), Validators.required],
      nacimientoFecha: ["", Validators.required],
      documentoNumero: ["", Validators.required],
      documentoExtranjero: [false, Validators.required],
      emergenciaMedica: "",
      atencionMedica: "",
      comentario: [""],
      referencia: [""],
      categoria: [1, Validators.required],
      esSinRutina: [false, Validators.required],
      esPrincipiante: [false, Validators.required],
      enviarConfirmacion: [true, Validators.required]
    });

    this.cargando = false;
    this.modal = this.modalService.show(this.template);
    this.onChanges();
  }

  onChanges(): void { }

  // ENVIAR ACCION
  onSubmit() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    this.cargando = true;
    this.mensaje = `Enviando Datos`;
    this.error = {};

    let contactos: any[] = [];

    if (this.f.email.value) {
      contactos.push({
        tipo: 0,
        valor: this.f.email.value
      });
    }

    if (this.f.celular.value) {
      contactos.push({
        tipo: 2,
        valor: this.f.celular.value
      });
    }

    let categorias: number[] = this.f.categoria.value != null ? [this.f.categoria.value] : [];
    let entidad = {
      nombre: this.f.nombre.value,
      apellido: this.f.apellido.value,
      nacimientoFecha: this.datePipe.transform(this.f.nacimientoFecha.value, "yyyy-MM-dd 00:00"),
      ingresoFecha: this.datePipe.transform(this.f.ingresoFecha.value, "yyyy-MM-dd 00:00"),
      documentoNumero: this.f.documentoNumero.value,
      documentoTipo: 0,
      contactos: contactos,
      categorias: categorias,
      referencia: this.f.documentoNumero.value,
      esPrinciante: this.f.esPrincipiante.value,
      emergenciaMedica: this.f.emergenciaMedica.value,
      atencionMedica: this.f.atencionMedica.value,
      esSinRutina: this.f.esSinRutina.value,
      enviarConfirmacion: this.f.enviarConfirmacion.value
    };

    this.entidadService.create(entidad).subscribe(
      (resultado: any) => {
        console.log(resultado);
        this.cargando = false;
        this.modal.hide();
        this.router.navigate([`/clientes/${resultado.entidad.id}`]);
      },
      (error: any) => {
        this.cargando = false;
        this.error = error;
      }
    );
  }
}
