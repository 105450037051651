import { Component } from "@angular/core";
import { DocumentosService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { IDocumento } from "@app/core/interfaces";

@Component({
  selector: "documentos-listado",
  templateUrl: "component.html"
})
export class DocumentosListado extends ListadoBaseComponent<IDocumento> {
  constructor(protected service: DocumentosService) {
    super(service);
  }
}
