import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { isEmpty } from "@app/utils";
import { AppService } from "./app.service";
@Injectable()
export class ReportesService {

  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) { }

  public clientes(filtros: any = {}): Observable<any> {
    let query = this.filtrosToQuery(filtros);
    return this.apiService.get(`/admin/reportes/monitores?${query}`);
  }

  public monitores(filtros: any = {}): Observable<any> {
    let query = this.filtrosToQuery(filtros);
    return this.apiService.get(`/admin/reportes/monitores?${query}`);
  }

  public ingresos(filtros: any = {}): Observable<any> {
    let query = this.filtrosToQuery(filtros);
    return this.apiService.get(`/admin/reportes/monitores?${query}`);
  }

  public ventas(filtros: any = {}): Observable<any> {
    let query = this.filtrosToQuery(filtros);
    return this.apiService.get(`/admin/reportes/monitores?${query}`);
  }

  private filtrosToQuery(filtros: any = {}): string {
    return Object.getOwnPropertyNames(filtros)
      .reduce((res: string[], key: string) => {
        if (!isEmpty(filtros[key])) res.push(`${key}=${filtros[key]}`);
        return res;
      }, [])
      .join("&");
  }
}
