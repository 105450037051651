import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  forwardRef
} from "@angular/core";
import "select2/dist/js/select2.min.js";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

declare var $: any;

@Directive({
  selector: "[select2]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Select2Directive),
      multi: true
    }
  ]
})
export class Select2Directive implements ControlValueAccessor, OnInit {
  @Input() public placeholder: string = "Seleccionar filtro";
  @Input() public allowClear: boolean = true;
  @Input() public options: any[] = [];

  public _select2: any;
  @Input()
  public _value: any = "";
  public propagateChange = (_: any) => { };

  constructor(private el: ElementRef) { }

  ngOnInit() {
    let that = this;
    this._select2 = $(this.el.nativeElement)
      .select2({
        data: that.options,
        allowClear: that.allowClear,
        placeholder: that.placeholder
      })
      .on("change", function (e) {
        that.value = that._select2.val();
      });
  }

  get value() {
    this._value = this._select2.val();
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.propagateChange(this._value);
  }

  writeValue(value: any) {
    let that = this;
    setTimeout(function () {
      that._value = value;
      that._select2.val(value);
      that._select2.trigger("change");
    }, 100);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }
}
