import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { IUsuarioAutenticado } from "../interfaces";
import { AppService } from "./app.service";
import { EnumTipoCuenta } from "../enums";
import { throwError } from "rxjs";

@Injectable({ providedIn: "root" })
export class AutenticacionService {
  constructor(private http: HttpClient, private appService: AppService) { }

  login(identificador: string, tipo: EnumTipoCuenta, datos: any) {
    return this.http
      .post<any>(`${environment.base_url}/login`, {
        identificador,
        tipo,
        datos
      })
      .pipe(
        map((usuario: IUsuarioAutenticado) => {
          if (usuario && usuario.token) {
            localStorage.setItem("usuario", JSON.stringify(usuario));
            this.appService.usuario$.next(usuario);
          }
        })
      );
  }
}
