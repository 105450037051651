import { Component, Input } from "@angular/core";
import { ConsumosService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { IConsumo } from "@app/core/interfaces";

@Component({
  selector: "consumos-listado-tabla",
  templateUrl: "component.html"
})
export class ConsumosListadoTabla extends ListadoBaseComponent<IConsumo> {
  constructor(protected consumosService: ConsumosService) {
    super(consumosService);
  }
}
