export enum EnumTipoContacto {
  EMAIL = 0,
  TELEFONO = 1,
  CELULAR = 2,
  FACEBOOK = 3,
  INSTAGRAM = 4
}

export namespace EnumTipoContacto {
  export function values(): { id: number; nombre: string }[] {
    return [
      { id: 0, nombre: "Email" },
      { id: 1, nombre: "Teléfono" },
      { id: 2, nombre: "Celular" },
      { id: 3, nombre: "Facebook" },
      { id: 4, nombre: "Instagram" },
      { id: 99, nombre: "Otro" }
    ];
  }
}
