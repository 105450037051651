import { Observable } from "rxjs";
import { ICargandoComponent, IUpdateComponent } from "@app/core/interfaces";

export class OnUpdateComponent implements IUpdateComponent, ICargandoComponent {
  public cargando: boolean;
  public error: boolean;
  public mensaje: string;

  constructor() {}

  public onUpdate(): Observable<any> {
    return this.cargarDatos();
  }

  cargarDatos(): Observable<any> {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }
}
