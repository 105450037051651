import { Component, OnInit, Input } from "@angular/core";
import { isEmpty } from "@app/utils";
import { ErrorInterface } from "@app/core/interfaces";

@Component({
  selector: "errors",
  templateUrl: "./component.html"
})
export class ErrorsComponent implements OnInit {
  @Input()
  error: ErrorInterface;

  get errors(): string[] {
    if (isEmpty(this.error)) return [];
    if (isEmpty(this.error.errors)) return [];
    return Object.keys(this.error.errors).map(key => { return this.error.errors[key] });
  }

  constructor() {}

  ngOnInit() { }
}
