import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import {
  trigger,
  style,
  animate,
  transition,
  state
} from "@angular/animations";
import {
  AppService,
  AccionesService
} from "../../../core/services";
import { AccionesComponent } from "../../acciones/acciones.component";
import { IUsuarioAutenticado, INotificacion, IPaginado } from "../../../core/interfaces";
import { Subscription } from "rxjs";
import { EnumTipoNotificacion } from "../../../core/enums";
import { environment } from "@env/environment";
import { dateToFechaDesdeISOString, dateToFechaHastaISOString } from "@app/utils";
import { LayoutService } from "../layout.service";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  animations: [
    trigger("fadeAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [style({ opacity: 0 }), animate("300ms")]),
      transition(":leave", [animate("300ms", style({ opacity: 0 }))])
    ]),
    trigger("bienvenidaAnimation", [
      transition(":enter", [
        style({ transform: "translateX(100%)", opacity: 0 }),
        animate("500ms", style({ transform: "translateX(0)", opacity: 1 }))
      ]),
      transition(":leave", [
        style({ transform: "translateX(0)", opacity: 1 }),
        animate("500ms", style({ transform: "translateX(100%)", opacity: 0 }))
      ])
    ])
  ]
})
export class MainLayoutComponent implements OnInit {
  @ViewChild("accionesComponent")
  public accionesComponent: AccionesComponent;

  public filtros = {};
  public paginado: IPaginado = {
    cantidadTotal: 0,
    cantidadPagina: 10,
    paginaSeleccionada: 1
  };

  public subscriptions: Subscription[] = [];
  public estado: {
    cargando: boolean;
    mensaje?: string;
    error?: boolean;
  };

  get navigationIsOpen():boolean{
    return !this.layoutService.layout.menuCollapsed;
  }

  get isFullscreenActived():boolean{
    return this.layoutService.isFullscreenActived;
  }

  constructor(
    private router: Router,
    private appService: AppService,
    private toastrService: ToastrService,
    private accionesService: AccionesService,
    private layoutService:LayoutService
  ) {
    let dia = new Date();
    this.filtros = {
      orden: "fecha",
      ordenId: "DESC",
      consumible: 1,
      estado: 1,
      fechaDesde : dateToFechaDesdeISOString(dia),
      fechaHasta : dateToFechaHastaISOString(dia)
    };
  }

  ngOnInit() {
    this.estado = {
      cargando: true,
      mensaje: "",
      error: false
    };

    this.subscriptions.push(
      this.appService.notificaciones$.subscribe({
        next: (notificaciones: [INotificacion]) => {
          notificaciones.forEach((notificacion: INotificacion) => {
            switch (notificacion.tipo) {
              case EnumTipoNotificacion.EXITO:
                this.toastrService.success(
                  notificacion.texto,
                  notificacion.titulo
                );
                break;
              case EnumTipoNotificacion.ERROR:
                this.toastrService.error(
                  notificacion.texto,
                  notificacion.titulo
                );
                break;
              case EnumTipoNotificacion.ADVERTENCIA:
                this.toastrService.warning(
                  notificacion.texto,
                  notificacion.titulo
                );
                break;
              case EnumTipoNotificacion.INFORMACION:
                this.toastrService.info(
                  notificacion.texto,
                  notificacion.titulo
                );
                break;
              default:
                break;
            }
          });
        }
      })
    );

    this.appService.getUsuario().subscribe(
      (usuario: IUsuarioAutenticado) => {
        this.estado.mensaje = `¡Hola ${usuario.nombre} ${usuario.apellido}!`;
        this.appService.update().subscribe(
          () => {
            this.estado.cargando = false;
            this.estado.error = false;
            this.subscriptions.push(
              this.appService.usuario$.subscribe({
                next: (usuario: IUsuarioAutenticado) => {
                  if (usuario == null) {
                    this.router.navigate(["/login"]);
                  }
                }
              })
            );

            this.subscriptions.push(
              this.accionesService.nuevaAccion.subscribe(accion => {
                this.accionesComponent.iniciarAccion(accion);
              })
            );
          },
          () => {
            this.estado.cargando = false;
            this.estado.error = true;
          }
        );
      },
      error => {
        console.log(error);
        this.router.navigate(["/login"]);
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onNavigationToggle() {
    this.layoutService.onCollapseMenuToggle();
  }

  onFullScreenToggle() {
    this.layoutService.onFullScreenToggle();
  }

  logout() {
    this.appService.logout();
  }
}
