export const dateToFechaDesdeISOString = (date: Date) => {
    let d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d.toISOString();
}

export const dateToFechaDesde = (date: Date) => {
    let d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d;
}

export const dateToFechaHastaISOString = (date: Date) => {
    let d = new Date(date);
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
    return d.toISOString();
}

export const dateToFechaHasta = (date: Date) => {
    let d = new Date(date);
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
    return d;
}

export const getFirtDayOfWeek = (date: Date) => {
    let d = new Date(date);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export const getFirtDayOfMonth = (date: Date) => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1)
}

export const getFirtDayOfYear = (date: Date) => {
    return new Date(new Date().getFullYear(), 0, 1)
}

export const getYesterday = (date: Date) => {
    return new Date((new Date()).getTime() - 24*60*60*1000);
}

export const isEmpty = (value: any) =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);
