import { Component, OnInit, Input } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { RibbonService, ribbonDefault } from "../../../core/services";
import { DrawerComponent } from "@app/shared/utils/drawer/drawer.component";
import { AccionInterface } from "@app/core/interfaces";

interface BreadCrumb {
  label: string;
  url?: string;
}

@Component({
  selector: "header-content",
  templateUrl: "./header-content.component.html"
})
export class HeaderContentComponent implements OnInit {
  public acciones:AccionInterface[] = [];
  public breadcrumbs: Array<BreadCrumb> = [];
  public page:BreadCrumb;
  public icono = "";
  private sub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ribbonService: RibbonService
  ) {
    ribbonService.acciones$.subscribe(acciones => {
      this.acciones = acciones;
    });
  }

  ngOnInit() {
    this.obtenerBreadcrumbs();

    this.sub = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(v => {
        this.acciones = [];
        this.obtenerBreadcrumbs();
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  obtenerBreadcrumbs() {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    this.page = this.breadcrumbs.pop();
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: Array<BreadCrumb> = []
  ): Array<BreadCrumb> {
    if (!route.routeConfig) {
      /*const label = "Recepción";
      const path = "recepcion";

      const nextUrl = `/${url}${path}`;
      const breadcrumb: BreadCrumb = {
        label: label,
        url: nextUrl
      };
      const newBreadcrumbs = [...breadcrumbs, breadcrumb];*/

      if (route.firstChild) {
        return this.buildBreadCrumb(route.firstChild, url, breadcrumbs);
      }
      return breadcrumbs;
    } else if (route.routeConfig.data) {
      this.extraerIcono(route);

      const label = route.routeConfig.data["pageTitle"];
      const path = route.routeConfig.path;

      if (route.firstChild) {
        const nextUrl = `/${url}${path}`;
        const breadcrumb: BreadCrumb = {
          label: label,
          url: nextUrl
        };
        const newBreadcrumbs = [...breadcrumbs, breadcrumb];
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
      }else{
        const breadcrumb: BreadCrumb = {
          label: label
        };
        return [...breadcrumbs, breadcrumb];
      };
    } else {
      if (route.firstChild) {
        return this.buildBreadCrumb(route.firstChild, url, breadcrumbs);
      }
      this.extraerIcono(route);
      return breadcrumbs;
    }
  }

  extraerIcono(route: ActivatedRoute) {
    if (route.routeConfig.data && route.routeConfig.data["pageIcon"]) {
      this.icono = "fa fa-" + route.routeConfig.data["pageIcon"];
    }
  }
}
