import { Component, Input, QueryList, ViewChildren } from "@angular/core";
import { ISuscripcion, IUpdateComponent } from "@app/core/interfaces";
import { SuscripcionesService } from "@app/core/services";
import { OnUpdateComponent } from "@app/shared/components/on-update.component";
import { Observable, zip } from "rxjs";

@Component({
  selector: "suscripcion-detalle",
  templateUrl: "suscripciones-detalle.component.html"
})
export class SuscripcionDetalle extends OnUpdateComponent {
  @ViewChildren("onUpdateComponent")
  public onUpdateComponents: QueryList<IUpdateComponent>;

  private _suscripcionId: number;
  get suscripcionId(): number {
    return this._suscripcionId;
  }

  get repeticionesIds(): string {
    if (this.suscripcion != null && this.suscripcion.repeticiones.length == 0) return null
    return this.suscripcion.repeticiones.reduce((res, item) => {
      res.push(item.id);
      return res
    }, []).join(",");
  }

  @Input()
  set suscripcionId(suscripcionId: number) {
    this._suscripcionId = suscripcionId;
    this.cargando = true;
    this.cargarDatos().subscribe(_ => {
      this.cargando = false;
    });
  }

  public suscripcion: ISuscripcion;

  constructor(private suscripcionesService: SuscripcionesService) {
    super();
  }

  cargarDatos(): Observable<any> {
    return new Observable(observer => {
      if (this.suscripcionId != null) {
        this.suscripcion = null;
        this.cargando = true;
        this.mensaje = "Obteniendo información de la suscripcion...";

        this.suscripcionesService.getById(this.suscripcionId).subscribe(
          (suscripcion: ISuscripcion) => {
            this.suscripcion = suscripcion;
            this.cargando = false;
            this.mensaje = "";
            let updates = this.onUpdateComponents.map(component => {
              return component.onUpdate();
            });
            if (updates.length > 0) {
              return zip(...updates).subscribe(
                _ => {
                  observer.next();
                  observer.complete();
                },
                error => {
                  observer.error();
                }
              );
            } else {
              observer.next();
              observer.complete();
            }
          },
          error => {
            this.suscripcion = null;
            this.cargando = false;
            (this.mensaje = error.error || "Error cargando datos."),
              observer.error();
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  actualizarSuscripcion(datos) {
    this.suscripcionesService
      .update(this.suscripcion.id, {
        categorias: datos
      })
      .subscribe(_ => { });
  }
}
