import { Pipe } from "@angular/core";
import { DiccionarioBasePipe } from "./diccionario.base.pipe";

@Pipe({
  name: "rol",
  pure: false
})
export class RolDiccionarioPipe extends DiccionarioBasePipe {
  protected diccionario;

  constructor() {
    super();
    this.diccionario = {
      0: "Desarrollador",
      1: "Administrador",
      2: "Operador",
      99: "Cliente"
    };
  }
}
