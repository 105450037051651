import { Component, Input, ViewChildren, QueryList } from "@angular/core";
import { EntidadService, VentasService } from "@app/core/services";
import { Observable, zip } from "rxjs";
import { IEntidad, IUpdateComponent, VentaInterface } from "@app/core/interfaces";
import { OnUpdateComponent } from "@app/shared/components/on-update.component";
import { environment } from "@env/environment";

@Component({
  selector: "venta-detalle",
  templateUrl: "venta-detalle.component.html"
})
export class VentasDetalle extends OnUpdateComponent {
  @ViewChildren("onUpdateComponent")
  public onUpdateComponents: QueryList<IUpdateComponent>;

  private _ventaId: number;
  get ventaId(): number {
    return this._ventaId;
  }

  @Input()
  set ventaId(ventaId: number) {
    this._ventaId = ventaId;
    this.cargando = true;
    this.cargarDatos().subscribe(_ => {
      this.cargando = false;
    });
  }

  public venta: VentaInterface;

  constructor(private ventasService: VentasService) {
    super();
  }

  cargarDatos(): Observable<any> {
    return new Observable(observer => {
      if (this.ventaId != null) {
        this.venta = null;
        this.cargando = true;
        this.mensaje = "Obteniendo información de la venta...";

        this.ventasService.getById(this.ventaId).subscribe(
          (venta: VentaInterface) => {
            this.venta = venta;
            this.cargando = false;
            this.mensaje = "";
            let updates = this.onUpdateComponents.map(component => {
              return component.onUpdate();
            });
            if (updates.length > 0) {
              return zip(...updates).subscribe(
                _ => {
                  observer.next();
                  observer.complete();
                },
                error => {
                  observer.error();
                }
              );
            } else {
              observer.next();
              observer.complete();
            }
          },
          error => {
            this.venta = null;
            this.cargando = false;
            (this.mensaje = error.error || "Error cargando datos."),
              observer.error();
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  actualizarVenta(datos) {
    this.ventasService
      .update(this.venta.id, {
        categorias: datos
      })
      .subscribe(_ => {});
  }
}
