import { Component } from "@angular/core";
import { UsuariosService } from "@app/core/services";
import { ListadoBaseComponent } from "@app/shared/components/listado.base.component";
import { UsuarioInterface } from "@app/core/interfaces";

@Component({
  selector: "usuarios-listado",
  templateUrl: "usuarios-listado.component.html"
})
export class UsuariosListadoComponent extends ListadoBaseComponent<UsuarioInterface> {

  public filtroEstados = [
    {
      nombre: "Bloqueados",
      valor: 0
    },
    {
      nombre: "Activos",
      valor: 1
    }
  ]

  constructor(
    protected service: UsuariosService
  ) {
    super(service);
  }
}
