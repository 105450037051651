import { Component, ElementRef, Input, OnInit, ViewChild, Renderer2, RendererFactory2 } from "@angular/core";

declare var $: any;

@Component({
  selector: "drawer",
  templateUrl: "./drawer.component.html",
})
export class DrawerComponent implements OnInit {
  @ViewChild("drawer")
  drawerElementRef: ElementRef;

  @Input()
  position: string = "right";

  @Input()
  iconClose: string = "times";

  @Input()
  visibility: string = "hidden";
  
  @Input()
  title: string = "";

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get isOpen(){
    return !(this.drawerElementRef.nativeElement.classList.contains('drawer-hidden'))
  }

  ngOnInit() {
  }

  close(){
    this.renderer.addClass(this.drawerElementRef.nativeElement, 'drawer-hidden');
  }

  open(){
    this.renderer.removeClass(this.drawerElementRef.nativeElement, 'drawer-hidden');
  }

  toggle(){
    if (this.drawerElementRef.nativeElement.classList.contains('drawer-hidden')){
      this.renderer.removeClass(this.drawerElementRef.nativeElement, 'drawer-hidden');
    }else{
      this.renderer.addClass(this.drawerElementRef.nativeElement, 'drawer-hidden');
    }
  }
}
