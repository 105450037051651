import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";

@Injectable()
export class ProductosService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/productos";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.productos
    ];
  }

  public getAcciones(item: any): AccionInterface[] {
    let acciones = [];
    if (item.estado == 1){
      acciones.push({
        titulo: "Desactivar",
        accion: EnumAccion.PRODUCTO_BLOQUEAR,
        icono: "lock",
        datos: { id: item.id, estado: 0 }
      })
    }
    if (item.estado == 0){
      acciones.push({
        titulo: "Activar",
        accion: EnumAccion.PRODUCTO_DESBLOQUEAR,
        icono: "unlock",
        datos: { id: item.id, estado: 0 }
      })
    }
    return acciones;
  }
}
