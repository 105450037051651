import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./../api.service";
import { BaseService } from "./base.service";
import { AppService } from "../app.service";
import { AccionInterface } from "@app/core/interfaces";
import { EnumAccion } from "@app/core/enums";

@Injectable()
export class DescuentosProductoService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected appService: AppService
  ) {
    super(http, apiService, appService);
    this.entityUrl = "/admin/descuentos/productos";
    this.servicios = [
      this.appService.servicios.inicio,
      this.appService.servicios.productos
    ];
  }

  public getAcciones(item: any): AccionInterface[] {
    return [
      {
        titulo: "Bloquear",
        accion: EnumAccion.DESCUENTO_PRODUCTO_BLOQUEAR,
        icono: "ban",
        datos: { id: item.id, estado: 0 }
      },
      {
        titulo: "Editar",
        accion: EnumAccion.DESCUENTO_PRODUCTO_EDITAR,
        icono: "pencil-alt",
        datos: { id: item.id }
      }
    ];
  }
}
