import { Component, EventEmitter, Output } from "@angular/core";

declare var $: any;

@Component({
  selector: "updatable-component",
  templateUrl: "./updatable-component.component.html",
})
export class UpdatableComponent{
  _loading:boolean = true;
  _title:string = "";
  _error:boolean = false;
  _message:string = "";

  @Output() retry:EventEmitter<any> = new EventEmitter();
  
  public loading(message?, title? ){
    this._error = false;
    this._loading = true;
    this._message = message?message:"Espere un momento por favor...";
    this._title = title?title:"Cargando Información";
  }

  public loaded(){
    this._error = false;
    this._loading = false;
    this._message = "";
    this._title = "";
  }

  public error(message?, title? ){
    this._error = true;
    this._loading = false;
    this._message = message?message:"Ha habido un error mientas se cargaba su información.";
    this._title = title?title:"Algo ha salido mal!";
  }

  retryClicked(){
    this.retry.emit();
  }
}
