import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, zip } from "rxjs";
import { IUsuarioAutenticado, INotificacion, IModulo, EventoInterface } from "../interfaces";
import { ApiService } from "./api.service";
import { SocketService } from "./socket.service";
import { TipoEventoEnum } from "../enums";

const servicioDefault: IModulo = {
  habilitado: true,
  permisos: [],
  notificaciones: []
};

@Injectable()
export class AppService {
  public usuario$: BehaviorSubject<IUsuarioAutenticado>;
  public notificaciones$: BehaviorSubject<INotificacion[]>;
  public servicios = {
    inicio: new BehaviorSubject({ ...servicioDefault }),
    clientes: new BehaviorSubject({ ...servicioDefault }),
    ingresos: new BehaviorSubject({ ...servicioDefault }),
    ventas: new BehaviorSubject({ ...servicioDefault }),
    suscripciones: new BehaviorSubject({ ...servicioDefault }),
    productos: new BehaviorSubject({ ...servicioDefault }),
    reportes: new BehaviorSubject({ ...servicioDefault }),
    usuarios: new BehaviorSubject({ ...servicioDefault })
  };

  constructor(protected apiService: ApiService, protected socketService: SocketService) {
    this.usuario$ = new BehaviorSubject(JSON.parse(localStorage.getItem("USUARIO")) || null);
    this.notificaciones$ = new BehaviorSubject([]);
    this.socketService.onEvento().subscribe((evento: EventoInterface) => {
      switch (evento.evento) {
        case TipoEventoEnum.CONSUMO_UPDATE:          
          let servicioNuevo = this.servicios.inicio.value;
          servicioNuevo.ultimaActualizacion = new Date();
          this.servicios.inicio.next(servicioNuevo);

          servicioNuevo = this.servicios.clientes.value;
          servicioNuevo.ultimaActualizacion = new Date();
          this.servicios.clientes.next(servicioNuevo);

          servicioNuevo = this.servicios.ingresos.value;
          servicioNuevo.ultimaActualizacion = new Date();
          this.servicios.ingresos.next(servicioNuevo);
          break;
        default:
          break;
      }
    });
  }

  public isUsuarioAutenticado(): boolean {
    return this.usuario$.value != null;
  }

  public getUsuario(): Observable<IUsuarioAutenticado> {
    return new Observable(observer => {
      this.apiService.get("/usuario").subscribe(
        (usuario: IUsuarioAutenticado) => {
          this.usuario$.next(usuario);
          observer.next(usuario);
          observer.complete();
        },
        error => {
          observer.error();
        }
      );
    });
  }

  public hasPermision(perimo:string):boolean {
    return this.usuario$.value != null ? this.usuario$.value.permisos.some(p=>{return p == perimo}) : false;
  }

  public update(): Observable<any> {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }

  public logout() {
    localStorage.removeItem("USUARIO");
    this.usuario$.next(null);
  }
}
