import { Component, OnInit, Input } from "@angular/core";
import {
  EntidadService,
  ContactosService,
  DescuentosProductoService
} from "@app/core/services";
import { IEntidad, ICargandoComponent } from "@app/core/interfaces";

@Component({
  selector: "entidad-datos-generales",
  templateUrl: "component.html"
})
export class EntidadDatosGenerales implements OnInit, ICargandoComponent {
  public cargando: boolean;
  public error: boolean;
  public mensaje: string;

  private _entidad: IEntidad;
  get entidad(): IEntidad {
    return this._entidad;
  }

  @Input()
  set entidad(entidad: IEntidad) {
    this._entidad = entidad;
    this.entidadDescuento =
      this.entidad.descuentos.length > 0 ? this.entidad.descuentos[0].id : null;
  }

  public entidadDescuento: number;

  public options = {
    mode: "inline",
    disabled: false,
    inline: true
  };

  public descuentos = [];

  constructor(
    private entidadService: EntidadService,
    private contactosService: ContactosService,
    private descuentosProductoService: DescuentosProductoService
  ) {}

  ngOnInit() {
    this.descuentosProductoService.get().subscribe(descuentos => {
      this.descuentos = descuentos.listado.map(d => {
        return { value: d.id, text: d.nombre };
      });
    });
  }

  onUpdateEntidad(datos: any) {
    this.entidadService.update(this.entidad.id, datos).subscribe(_ => {});
  }

  onUpdateContacto(contactoId: number, datos: any) {
    this.contactosService.update(contactoId, datos).subscribe(_ => {});
  }
}
